
import './App.css';

import Authorization from './AuthSignature';

function App() {
  return (
    <div className="App">
          <Authorization />
    </div>
  );
}

export default App;