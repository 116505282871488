import React, { useRef, useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import StarIcon from "@material-ui/icons/StarBorder";
import SignaturePad from "react-signature-canvas";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import TextField from "@material-ui/core/TextField";
import {
  createTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { AlertTitle } from "@material-ui/lab";
import LogoPF from "./PF.png";
import LogoSG from "./SG.png";
import LogoBF from "./BF.png";
import LogoRECORDARI from "./Recordari.png";
import { format, parseISO, isValid } from "date-fns";
import Paper from "@material-ui/core/Paper";
import LibraryAddIcon from "@material-ui/icons/LibraryAdd";
import queryString from "query-string";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import BlockIcon from "@material-ui/icons/Block";
import { green } from "@material-ui/core/colors";
import EditIcon from "@material-ui/icons/Edit";
import ClientEdit from "./Edit";
import clsx from "clsx";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://jgarcialopez.com.mx/">
        J. GARCÍA LÓPEZ CASAS FUNERARIAS.
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const numberFormat = (value) =>
  new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
  }).format(value);

const theme = createTheme({
  palette: {
    primary: green,
  },
});

const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: "wrap",
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  margin: {
    margin: theme.spacing(1, 1, 1, 0),
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
    color: "#00349c",
  },
  cardContract: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },

  signatureCanvas: {
    border: "2px solid #000",
    background: "#ffffff",
    width: "100%",
    minHeight: "400px",
    zIndex: 99999,
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    background: "#fff",
    color: "#000",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
    width: "fit-content",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function Contract(props) {
  const classes = useStyles();
  const sigCanvas = useRef({});
  const clear = () => sigCanvas.current.clear();
  const [imageURL, setImageURL] = useState(null);
  const [contract, setContract] = useState({});
  const [states, setStates] = useState([]);
  const [banks, setBanks] = useState([]);
  const [occupations, setOccupations] = useState([]);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [successButton, setSuccessButton] = React.useState(false);
  const [token, setToken] = useState();
  const [open, setOpen] = useState(true);
  const [plate, setPlate] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDateDialog, setOpenDateDialog] = useState(false);
  const [openCardsDialog, setOpenCardsDialog] = useState(false);
  const [openBeneficiaryDialog, setOpenBeneficiaryDialog] = useState(false);
  const [beneficiaryId, setBeneficiaryId] = useState(0);
  const [cardId, setCardId] = useState(0);
  const [state, setState] = React.useState({
    vertical: "top",
    horizontal: "center",
    checkedF: false,
    checkedB: false,
    checkedAcceptBilling: false,
  });
  const [taxRegimeName, setTaxRegimeName] = useState( null );
  
  //PDF
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const buttonClassname = clsx({
    [classes.buttonSuccess]: successButton,
  });

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  let myHeaders = new Headers();
  let dataQuery = queryString.parse(window.location.search);

  const signin = () => {
    let raw = JSON.stringify({
      token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9",
    });

    let requestOptionsN = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_PUBLIC_URL}/user/auth/signin`,
      requestOptionsN
    )
      .then((response) => response.json())
      .then((result) => setToken(result.body))
      .catch((error) => console.log("TOKEN error", error));
  };

  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  let requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  const save = () => {
    if (!loading) {
      setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
      setLoading(true);
      let requestOptionsSave = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          HolderSignature: sigCanvas.current
            .getTrimmedCanvas()
            .toDataURL("image/png"),
        }),
        redirect: "follow",
      };
      fetch(
        `${process.env.REACT_APP_PUBLIC_URL}/contract/${dataQuery.Id}/update-signature`,
        requestOptionsSave
      )
        .then((response) => response.json())
        .then((result) => {
          setSuccess(result.body);
          setLoading(false);
        })
        .catch((error) => console.log("error", error));
    }
  };

  const saveSAP = () => {
    if (!loading) {
      setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
      setLoading(true);
      let requestOptionsSave = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          HolderSignature: sigCanvas.current
            .getTrimmedCanvas()
            .toDataURL("image/png"),
        }),
        redirect: "follow",
      };
      fetch(
        `${process.env.REACT_APP_PUBLIC_URL}/contract/${dataQuery.Contrato}/update-signature-sap`,
        requestOptionsSave
      )
        .then((response) => response.json())
        .then((result) => {
          setSuccess(result.body);
          setLoading(false);
        })
        .catch((error) => console.log("error", error));
    }
  };

  const saveSAPCession = () => {
    if (!loading) {
      setLoading(true);
      setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
      let requestOptionsSave = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          Cession: true,
          HolderSignature: sigCanvas.current
            .getTrimmedCanvas()
            .toDataURL("image/png"),
        }),
        redirect: "follow",
      };
      fetch(
        `${process.env.REACT_APP_PUBLIC_URL}/contract/${dataQuery.Contrato}/update-signature-sap`,
        requestOptionsSave
      )
        .then((response) => response.json())
        .then((result) => {
          setSuccess(result.body);
          setLoading(false);
        })
        .catch((error) => console.log("error", error));
    }
  };

  const saveSAPPlate = () => {
    if (!loading) {
      setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
      setLoading(true);
      let requestOptionsSave = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          Plate: plate,
          HolderSignature: sigCanvas.current
            .getTrimmedCanvas()
            .toDataURL("image/png"),
        }),
        redirect: "follow",
      };
      fetch(
        `${process.env.REACT_APP_PUBLIC_URL}/contract/${dataQuery.Contrato}/update-signature-sap`,
        requestOptionsSave
      )
        .then((response) => response.json())
        .then((result) => {
          setSuccess(result.body);
          setLoading(false);
        })
        .catch((error) => console.log("error", error));
    }
  };

  const handleSaveData = () => {
    if (!loading) {
      setSuccessButton(false);
      setLoading(true);
      let requestOptionsSave = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          ...contract,
          ...{
            ContractHolder: `${contract.Name} ${contract.LastName} ${contract.MotherLastName}`,
          },
        }),
        redirect: "follow",
      };
      fetch(
        `${process.env.REACT_APP_PUBLIC_URL}/contract/${dataQuery.Id}/update`,
        requestOptionsSave
      )
        .then((response) => response.json())
        .then((result) => {
          setOpenDialog(false);
          setOpenBeneficiaryDialog(false);
          setOpenCardsDialog(false);
          setOpenDateDialog(false);
          setOpenAlert(true);
          setContract(result.body);
          setSuccessButton(true);
          setLoading(false);
        })
        .catch((error) => console.log("error", error));
    }
  };

  const fetchContract = fetch(`${process.env.REACT_APP_PUBLIC_URL}/contract/${dataQuery.Id}/get`, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      if (result.status === 200) {
        let address = result.body.Addresses.find(
          (item) => item.Id === result.body.AddressId
        );
        let data = { ...result.body, Address: address };
        return data;
      }
    })
    .catch((error) => console.log("error", error));

  const fetchContractPDF = fetch(`${process.env.REACT_APP_PUBLIC_URL}/contract/${dataQuery.Contrato}/sap?${dataQuery.Recordari ? 'recordari=1' : dataQuery.Coverage ? 'coverage=1' : dataQuery.cession ? 'cession=1' : 'insurance=1&pdf=1'}`, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      if (result.status === 200) {
        let data = { ...result.body };
        if (data.filename) {
          data.filename = data.filename.replace(" ", "%20");
        }
        return data;
      }
    }).catch((error) => console.log("error", error));

  const fetchStates = fetch(
    `${process.env.REACT_APP_PUBLIC_URL}/catalog/addresses/states`,
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => {
      if (result.status === 200) {
        return result.body;
      }
    })
    .catch((error) => console.log("error", error));

  const fetchBanks = fetch(
    `${process.env.REACT_APP_PUBLIC_URL}/catalog/banks`,
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => {
      if (result.status === 200) {
        return result.body;
      }
    })
    .catch((error) => console.log("error", error));

  const fetchOccupations = fetch(
    `${process.env.REACT_APP_PUBLIC_URL}/catalog/occupations`,
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => {
      if (result.status === 200) {
        return result.body;
      }
    })
    .catch((error) => console.log("error", error));

  const fetchTaxRegimeName = fetch(
    `${process.env.REACT_APP_PUBLIC_URL}/catalog/${contract?.TaxRegime}/find-taxregime`,
    requestOptions
  )
  .then( (response) => response.json())
  .then( (result) => {
//console.log( "<<<<<<< RESULT (449) >>>>>>>>", result.body );
      if (result.body.Name)  {
          setTaxRegimeName( result.body.Name );
      }
    } )
    .catch( (error) => console.log( "error", error ) );

  
  const calculateAge = (date) => {
    let date2;
    if (typeof date === "string") {
      date2 = parseISO(date);
      date = date2;
    }
    if (date) {
      let today = new Date();
      let ageCalc = today.getFullYear() - date.getFullYear();
      let birthdate = today.getMonth() - date.getMonth();

      if (!isNaN(ageCalc)) {
        if (
          birthdate < 0 ||
          (birthdate === 0 && today.getDate() < date.getDate())
        ) {
          ageCalc--;
        }
        return ageCalc;
      }
      return ageCalc;
    }
  };

  useEffect(() => {
    signin();
  }, []);

  useEffect(() => {
    if (dataQuery.Id && token) {
      Promise.all([fetchContract, fetchStates, fetchBanks, fetchOccupations])
        .then((values) => {
          setContract({ ...contract, ...values[0] });
          if (Array.isArray(values[1])) {
            setStates(values[1]);
          }
          if (Array.isArray(values[2])) {
            setBanks(values[2]);
          }
          if (Array.isArray(values[3])) {
            setOccupations(values[3]);
          }
          setOpen(false);
        })
        .catch((error) => console.log("error", error));
    } else if (dataQuery.Contrato && token) {
      Promise.all([fetchContractPDF])
        .then((values) => {
          setContract({ ...contract, ...values[0] });
          setOpen(false);
        })
        .catch((error) => console.log("error", error));
    }
  }, [token]);

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

/*   useEffect( () => {
    if (contract?.TaxRegime && contract?.TaxRegime.length == 3) {
      fetchTaxRegimeName();
    }
  }, [contract?.TaxRegime]); */

  const { vertical, horizontal } = state;

  return (

    <React.Fragment>
      <CssBaseline />
      {/* Hero unit */}
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={openAlert}
        anchorOrigin={{ vertical, horizontal }}
        key={vertical + horizontal}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          Actualizado correctamente
        </Alert>
      </Snackbar>
      {openDialog ||
      openCardsDialog ||
      openBeneficiaryDialog ||
      openDateDialog ? (
        <ClientEdit
          loading={loading}
          successButton={successButton}
          openDialog={openDialog}
          contract={contract}
          setContract={setContract}
          states={states}
          banks={banks}
          occupations={occupations}
          setOpenDialog={setOpenDialog}
          setOpenCardsDialog={setOpenCardsDialog}
          handleSaveData={handleSaveData}
          openCardsDialog={openCardsDialog}
          openBeneficiaryDialog={openBeneficiaryDialog}
          setOpenBeneficiaryDialog={setOpenBeneficiaryDialog}
          beneficiaryId={beneficiaryId}
          cardId={cardId}
          openDateDialog={openDateDialog}
          setOpenDateDialog={setOpenDateDialog}
          token={token}
          setTaxRegimeName={setTaxRegimeName}
          taxRegimeName={taxRegimeName}
        />
      ) : (
        <></>
      )}

      { (!contract.cession || success) && (contract.Status == 1 || success || (!contract.Status && contract.contract && contract.contract.U_ALC_FIRMACONTRATO && contract.contract.U_ALC_FIRMACONTRATO != null)) ? (
        <Container
          maxWidth="md"
          component="main"
          style={{ background: "#ffffff", width: "100%", height: "100vh" }}
        >
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
            style={{ padding: "4rem 0" }}
          >
            {contract.BusinessLine?.length > 1 ? (
              <img
                src={
                  contract.BusinessLine === "SG"
                    ? LogoSG
                    : contract.BusinessLine === "PF"
                    ? LogoPF
                    : LogoBF
                }
                alt="Privisión Final"
                style={{ width: "200px" }}
              />
            ) : (
              <></>
            )}
          </Grid>
          <Grid
            container
            spacing={2}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              variant="h5"
              align="center"
              color="textSecondary"
              component="p"
            >
              Se ha realizado la firma correctamente.
            </Typography>
            <CheckCircleIcon
              style={{ fontSize: 80, margin: 40, color: "#5dc76a" }}
            />
            <Typography
              variant="h6"
              align="center"
              color="textSecondary"
              component="p"
            >
              Agradecemos su preferencia.
            </Typography>
          </Grid>
        </Container>
      ) : contract.Status === -1 ? (
        <Container
          maxWidth="md"
          component="main"
          style={{ background: "#ffffff" }}
        >
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
            style={{ padding: "4rem 0 2rem" }}
          >
            <img
              src={contract.BusinessLine === "PF" ? LogoPF : (contract.BusinessLine === "SG") ? LogoSG : LogoBF}
              alt="Privisión Final"
              style={{ width: "200px" }}
            />
          </Grid>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid
              container
              direction="column"
              justifyContent="flex-end"
              alignItems="flex-end"
              style={{ margin: "4rem 0" }}
            >
              <div>
                Fecha del contrato{" "}
                {format(
                  parseISO(contract?.ContractDate ?? new Date()),
                  "dd/MM/yyyy"
                )}
              </div>
              <div>Semana {contract?.Week ?? "Sin información"}</div>
              <div>Número de contrato {contract?.ContractNumber ?? " "}</div>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            justifyContent="center"
            spacing={2}
          >
            <Grid item xs={12} sm={12}>
              <Typography
                variant="h5"
                align="center"
                color="textSecondary"
                component="p"
                style={{
                  backgroundImage:
                    "linear-gradient(to right, rgb(0 59 119), #0367b7)",
                  color: "#fff",
                  margin: " 0 0 2rem 0",
                  padding: "0.5rem",
                  boxShadow: "0.5rem 0.5rem .25rem #a0a0a0b8",
                  borderRadius: "0.5rem",
                }}
              >
                Datos del titular del contrato
              </Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              {contract.PaymentLinkPath && (
                <Button
                  variant="contained"
                  color="secondary"
                  href={contract.PaymentLinkPath}
                  className={classes.margin}
                  startIcon={<MonetizationOnIcon />}
                >
                  Pago a distancia
                </Button>
              )}
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button
                variant="outlined"
                onClick={handleClickOpen}
                className={classes.margin}
                startIcon={<EditIcon />}
              >
                Editar
              </Button>
            </Grid>

            <Grid item xs={12} sm={12}>
              Nombre del titular <br />
              <b>
                {`${contract?.Name} ${" "} ${contract?.LastName} ${
                  contract?.MotherLastName
                }` ?? "Sin información"}
                {/* <TextField  fullWidth name="ContractHolder" value={contract?.ContractHolder ?? "Sin información"} onChange={(event)=> {
                setContract({...contract, [event.target.name]: event.target.value.toUpperCase()})
              }} /> */}
              </b>
            </Grid>
            <Grid item xs={12} sm={3}>
              Género <br />
              <b>
                {contract?.Gender === "M"
                  ? "Masculino"
                  : contract?.Gender === "F"
                  ? "Femenino"
                  : "Indeterminado"}
              </b>
            </Grid>

            <Grid item xs={12} sm={3}>
              Fecha de nacimiento <br />
              <b>
                {contract?.BirthDate &&
                  format(parseISO(contract?.BirthDate), "dd/MM/yyyy")}
              </b>
            </Grid>
            <Grid item xs={12} sm={3}>
              Lugar de nacimiento <br />
              <b>
                {states?.find((item) => item.Code === contract?.BirthPlace)
                  ?.Name ?? "Sin informacón"}
              </b>
            </Grid>

            <Grid item xs={12} sm={3}>
              CURP <br />
              <b>{contract?.PopulationReg ?? "Sin información"}</b>
            </Grid>
            <Grid item xs={12} sm={3}>
              Número de celular <br />
              <b>
                {contract?.MobilePhone?.length > 0
                  ? contract?.MobilePhone
                  : "Sin información"}
              </b>
            </Grid>
            <Grid item xs={12} sm={3}>
              Número de casa <br />
              <b>
                {contract?.HomePhone?.length > 0
                  ? contract?.HomePhone
                  : "Sin información"}
              </b>
            </Grid>
            <Grid item xs={12} sm={3}>
              Número de oficina <br />
              <b>{contract?.OfficePhone ?? "Sin información"}</b>
            </Grid>
            <Grid item xs={12} sm={3}>
              Extensión <br />
              <b>{contract?.ExtPhone ?? "Sin información"}</b>
            </Grid>
            <Grid item xs={12} sm={3}>
              Email <br />
              <b>{contract?.Email ?? "Sin información"}</b>
            </Grid>
            <Grid item xs={12} sm={9}>
              RFC del titular
              <br />
              <b>{contract?.FederalReg ?? "Sin información"}</b>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography
                variant="h6"
                align="left"
                color="textSecondary"
                component="p"
              >
                Dirección
              </Typography>
            </Grid>
            <Grid item xs={12} sm={2}>
              CP <br />
              <b>
                {contract?.Addresses.find(
                  (item) => item.Id == contract?.AddressId
                )?.ZipCode ?? "Sin información"}
              </b>
            </Grid>
            <Grid item xs={12} sm={6}>
              Calle <br />
              <b>
                {contract?.Addresses.find(
                  (item) => item.Id == contract?.AddressId
                )?.Street ?? "Sin información"}
              </b>
            </Grid>
            <Grid item xs={12} sm={2}>
              N° Ext <br />
              <b>
                {contract?.Addresses.find(
                  (item) => item.Id == contract?.AddressId
                )?.StreetNo ?? "Sin número"}
              </b>
            </Grid>
            <Grid item xs={12} sm={2}>
              N° Int <br />
              <b>
                {contract?.Addresses.find(
                  (item) => item.Id == contract?.AddressId
                )?.StreetNoInt ?? "N/A"}
              </b>
            </Grid>
            <Grid item xs={12} sm={3}>
              Colonia <br />
              <b>
                {contract?.Addresses.find(
                  (item) => item.Id == contract?.AddressId
                )?.Suburb ?? "Sin información"}
              </b>
            </Grid>
            <Grid item xs={12} sm={3}>
              Delegación <br />
              <b>
                {contract?.Addresses.find(
                  (item) => item.Id == contract?.AddressId
                )?.County ?? "Sin información"}
              </b>
            </Grid>
            <Grid item xs={12} sm={3}>
              Ciudad <br />
              <b>
                {contract?.Addresses.find(
                  (item) => item.Id == contract?.AddressId
                )?.City ?? "Sin información"}
              </b>
            </Grid>
            <Grid item xs={12} sm={3}>
              Estado <br />
              <b>
                {states?.find(
                  (item) =>
                    item.Code ===
                    contract?.Addresses.find(
                      (item) => item.Id == contract?.AddressId
                    )?.StateCode
                )?.Name ?? "Sin informacón"}
              </b>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography
                variant="h6"
                align="left"
                color="textSecondary"
                component="p"
              >
                Datos de facturación
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              {contract?.InvoiceFederalReg?.length == 13
                ? "Nombre persona física"
                : "Razón social"}
              <br />
              <b>
                {contract?.InvoiceCustomerName?.length > 0
                  ? contract?.InvoiceCustomerName
                  : "Sin información"}
              </b>
            </Grid>
            <Grid item xs={12} sm={3}>
              RFC para facturación <br />
              <b>
                {contract?.InvoiceFederalReg?.length > 0
                  ? contract?.InvoiceFederalReg
                  : "Sin información"}
              </b>
            </Grid>
            <Grid item xs={12} sm={3}>
              Correo electrónico <br />
              <b>
                {contract?.InvoicedEmail?.length > 0
                  ? contract?.InvoicedEmail
                  : "Sin información"}
              </b>
            </Grid>

            <Grid item xs={12} sm={6}>
              Régimen Fiscal
              <br />
              <b>
                {contract?.TaxRegime && contract?.TaxRegime.length == 3
                  ? `${contract?.TaxRegime} - ${taxRegimeName}`
                  : "Sin información"}
              </b>
            </Grid>
            <Grid item xs={12} sm={6}>
              Código Postal Fiscal
              <br />
              <b>
                {contract?.TaxZipCode && contract?.TaxZipCode.length == 5
                  ? contract?.TaxZipCode
                  : "Sin información"}
              </b>
            </Grid>

          </Grid>
          {contract?.Beneficiaries?.filter((item) => item.DocumentType === 1)
            .length >= 1 ? (
            <Grid
              container
              direction="row"
              alignItems="flex-start"
              justifyContent="center"
              spacing={2}
            >
              <Grid item xs={12} sm={12}>
                <Typography
                  variant="h5"
                  align="center"
                  color="textSecondary"
                  component="p"
                  style={{
                    backgroundImage:
                      "linear-gradient(to right, rgb(25 3 80), rgb(49 8 148))",
                    color: "#fff",
                    margin: "4rem 0 2rem",
                    padding: "0.5rem",
                    boxShadow: "0.5rem 0.5rem .25rem #a0a0a0b8",
                    borderRadius: "0.5rem",
                  }}
                >
                  Beneficiarios del contrato
                </Typography>
              </Grid>

              {contract?.Beneficiaries?.filter(
                (item) => item.DocumentType === 1
              ).map((beneficiary) => (
                <>
                  <Grid item xs={12} sm={3} key={beneficiary.Id}>
                    Tipo de beneficiario <br />
                    <b>
                      {beneficiary?.BeneficiaryType === "BN"
                        ? "Beneficiario"
                        : "Titular Sustituto"}
                    </b>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    Nombre <br />
                    <b>
                      {`${beneficiary?.Name ?? ""} ${" "} ${
                        beneficiary?.LastName ?? ""
                      }
                      ${beneficiary?.MotherLastName ?? ""}`}
                    </b>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setOpenBeneficiaryDialog(true);
                        setBeneficiaryId(beneficiary.Id);
                      }}
                      className={classes.margin}
                      startIcon={<EditIcon />}
                    >
                      Editar
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    Género <br />
                    <b>
                      {beneficiary?.Gender === "M"
                        ? "Masculino"
                        : beneficiary?.Gender === "F"
                        ? "Femenino"
                        : "Indeterminado"}
                    </b>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    Fecha de nacimiento <br />
                    <b>
                      {beneficiary.BirthDate &&
                        format(parseISO(beneficiary?.BirthDate), "dd/MM/yyyy")}
                    </b>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    Número de celular <br />
                    <b>{beneficiary?.MobilePhone ?? "Sin información"}</b>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    Email <br />
                    <b>{beneficiary?.Email ?? "Sin información"}</b>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    Domicilio <br />
                    <b>
                      {beneficiary?.Address
                        ? `${beneficiary?.Address[0]?.Street ?? ""}${
                            beneficiary?.Address[0]?.Street ? ", " : ""
                          }` +
                          `${beneficiary?.Address[0]?.StreetNo ?? ""}${
                            beneficiary?.Address[0]?.StreetNo ? ", " : ""
                          }` +
                          `${beneficiary?.Address[0]?.StreetNoInt ?? ""}${
                            beneficiary?.Address[0]?.StreetNoInt ? ", " : ""
                          }` +
                          `${beneficiary?.Address[0]?.Suburb ?? ""}${
                            beneficiary?.Address[0]?.Suburb ? ", " : ""
                          }` +
                          `${beneficiary?.Address[0]?.ZipCode ?? ""}${
                            beneficiary?.Address[0]?.ZipCode ? ", " : ""
                          }` +
                          `${beneficiary?.Address[0]?.County ?? ""}${
                            beneficiary?.Address[0]?.County ? ", " : ""
                          }` +
                          `${
                            states.find(
                              (option) =>
                                option.Code ===
                                beneficiary?.Address[0]?.StateCode
                            )?.Name ?? ""
                          }${beneficiary?.Address[0]?.StateCode && ""}`
                        : "Sin información"}
                    </b>
                  </Grid>
                </>
              ))}
            </Grid>
          ) : (
            <></>
          )}
          {contract?.Beneficiaries?.filter((item) => item.DocumentType === 2)
            .length >= 1 && calculateAge(contract.BirthDate) <= 60 ? (
            <Grid
              container
              direction="row"
              alignItems="flex-start"
              justifyContent="center"
              spacing={2}
            >
              <Grid item xs={12} sm={12}>
                <Typography
                  variant="h5"
                  align="center"
                  color="textSecondary"
                  component="p"
                  style={{
                    backgroundImage:
                      "linear-gradient(to right, rgb(25 3 80), rgb(49 8 148))",
                    color: "#fff",
                    margin: "4rem 0 2rem",
                    padding: "0.5rem",
                    boxShadow: "0.5rem 0.5rem .25rem #a0a0a0b8",
                    borderRadius: "0.5rem",
                  }}
                >
                  Beneficiarios del seguro de vida
                </Typography>
              </Grid>
              {contract?.Beneficiaries?.filter(
                (item) => item.DocumentType === 2
              ).map((beneficiary, index) => (
                <>
                  {/*  <Grid item xs={12} sm={4}>
                Tipo de beneficiario <br />
                <b>{beneficiary?.BeneficiaryType === "BN" ?  "Beneficiario": "Titular Sustituto"}</b>
              </Grid> */}
                  <Grid item xs={12} sm={6} key={beneficiary.Id + index}>
                    Nombre <br />
                    <b>
                      {beneficiary?.Name ?? ""} {beneficiary?.LastName ?? ""}{" "}
                      {beneficiary?.MotherLastName ?? ""}
                    </b>
                  </Grid>
                  <Grid item xs={12} sm={3}></Grid>
                  <Grid item xs={12} sm={3}>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setOpenBeneficiaryDialog(true);
                        setBeneficiaryId(beneficiary.Id);
                      }}
                      className={classes.margin}
                      startIcon={<EditIcon />}
                    >
                      Editar
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    Parentesco <br />
                    <b>{beneficiary?.Relationship ?? ""}</b>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    Porcentaje <br />
                    <b>{beneficiary?.Percent ?? 0}%</b>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    Género <br />
                    <b>
                      {beneficiary?.Gender === "M"
                        ? "Masculino"
                        : beneficiary?.Gender === "F"
                        ? "Femenino"
                        : "Indeterminado"}
                    </b>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    Fecha de nacimiento <br />
                    <b>
                      {beneficiary.BirthDate &&
                        format(parseISO(beneficiary?.BirthDate), "dd/MM/yyyy")}
                    </b>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    Número de celular <br />
                    <b>{beneficiary?.MobilePhone ?? "Sin información"}</b>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    Email <br />
                    <b>{beneficiary?.Email ?? "Sin información"}</b>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    Domicilio <br />
                    <b>
                      <b>
                        {beneficiary?.Address
                          ? `${beneficiary?.Address[0]?.Street ?? ""}${
                              beneficiary?.Address[0]?.Street ? ", " : ""
                            }` +
                            `${beneficiary?.Address[0]?.StreetNo ?? ""}${
                              beneficiary?.Address[0]?.StreetNo ? ", " : ""
                            }` +
                            `${beneficiary?.Address[0]?.StreetNoInt ?? ""}${
                              beneficiary?.Address[0]?.StreetNoInt ? ", " : ""
                            }` +
                            `${beneficiary?.Address[0]?.Suburb ?? ""}${
                              beneficiary?.Address[0]?.Suburb ? ", " : ""
                            }` +
                            `${beneficiary?.Address[0]?.ZipCode ?? ""}${
                              beneficiary?.Address[0]?.ZipCode ? ", " : ""
                            }` +
                            `${beneficiary?.Address[0]?.County ?? ""}${
                              beneficiary?.Address[0]?.County ? ", " : ""
                            }` +
                            `${
                              states.find(
                                (option) =>
                                  option.Code ===
                                  beneficiary?.Address[0]?.StateCode
                              )?.Name ?? ""
                            }${beneficiary?.Address[0]?.StateCode && ""}`
                          : "Sin información"}
                      </b>
                    </b>
                  </Grid>
                </>
              ))}
            </Grid>
          ) : (
            <></>
          )}

          <Grid container spacing={2} alignItems="flex-end">
            {contract?.Details?.map((item) => (
              // Enterprise card is full width at sm breakpoint
              <Grid item xs={12} sm={12} key={item.Id + item.ItemCode}>
                <Card>
                  <CardHeader
                    title={item.ItemName}
                    titleTypographyProps={{ align: "center" }}
                    subheaderTypographyProps={{ align: "center" }}
                    action={
                      item.ItemClass === "Planes" ? (
                        <StarIcon style={{ color: "rgb(5 162 46)" }} />
                      ) : (
                        <LibraryAddIcon />
                      )
                    }
                    className={classes.cardHeader}
                  />
                  <CardContent>
                    <div className={classes.cardContract}>
                      <Typography
                        component="h3"
                        variant="h5"
                        color="textPrimary"
                      >
                        {numberFormat(item.UnitPrice)} x {item.Quantity} ={" "}
                        {numberFormat(item.ItemTotal)}
                      </Typography>
                      <Typography variant="h6" color="textSecondary">
                        /MXN
                      </Typography>
                    </div>
                    <ul>
                      {contract?.DetailItems?.filter(
                        (det) => det.Father === item.ItemCode
                      )?.map((detail) => (
                        <Typography
                          component="li"
                          variant="caption"
                          align="left"
                          key={detail.Id}
                        >
                          {detail.Quantity} {detail.ItemName}
                        </Typography>
                      ))}
                    </ul>
                  </CardContent>
                  <CardActions>
                    <Button
                      fullWidth
                      variant={item.buttonVariant}
                      color="primary"
                    >
                      {item.buttonText}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            justifyContent="center"
            spacing={2}
          >
            <Grid item xs={12} sm={12}>
              <Typography
                variant="h5"
                align="center"
                color="textSecondary"
                component="p"
                style={{
                  backgroundImage: "linear-gradient(to right,#bf9100, #ffc100)",
                  color: "#fff",
                  margin: "4rem 0 2rem",
                  padding: "0.5rem",
                  boxShadow: "0.5rem 0.5rem .25rem #a0a0a0b8",
                  borderRadius: "0.5rem",
                }}
              >
                Resumen de {contract?.NumberPayments > 1 ? "financiamiento" : "pago"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              Plan contratado
              <br />
              <b>{contract?.PlanDescription ?? "Sin información"}</b>
            </Grid>
            <Grid item xs={12} sm={4}>
              Precio Total del contrato
              <br />
              <b> {numberFormat(contract?.ContractPrice ?? 0)}</b>
            </Grid>
            <Grid item xs={12} sm={4}>
              Método de pago <br />
              <b>{contract?.FinancingDescription ?? 0}</b>
            </Grid>
            {contract?.DiscountAmount > 0 &&
              <Grid item xs={12} sm={4}>
                Beneficio <br />
                <b>{numberFormat(contract?.DiscountAmount ?? 0)}</b>
              </Grid>
            }
            {contract?.NumberPayments > 1 &&
              <>
                <Grid item xs={12} sm={4}>
                  Mensualidades <br />
                  <b>{contract?.NumberPayments - 1 ?? 0}</b>
                </Grid>
                <Grid item xs={12} sm={4}>
                  Monto de mensualidad
                  <br />
                  <b>
                    {contract?.AmountFinanced > 0 && contract?.NumberPayments
                      ? numberFormat(
                          contract?.AmountFinanced / (contract?.NumberPayments - 1)
                        )
                      : numberFormat(0)}
                  </b>
                </Grid>
                <Grid item xs={12} sm={4}>
                  Monto financiado
                  <br />
                  <b>{numberFormat(contract?.AmountFinanced ?? 0)}</b>
                </Grid>
                <Grid item xs={12} sm={4}>
                  Monto primera mensualidad
                  <br />
                  <b>{numberFormat(contract?.AmountPaid ?? 0)}</b>
                </Grid>
                <Grid item xs={12} sm={4}></Grid>
              </>
            }
          </Grid>
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            justifyContent="center"
          >
            <Grid item xs={12} sm={12}>
              <Typography
                variant="h5"
                align="center"
                color="textSecondary"
                component="p"
                style={{
                  backgroundImage: "linear-gradient(to right,#bf9100, #ffc100)",
                  color: "#fff",
                  margin: "4rem 0 2rem",
                  padding: "0.5rem",
                  boxShadow: "0.5rem 0.5rem .25rem #a0a0a0b8",
                  borderRadius: "0.5rem",
                }}
              >
                Forma(s) de pago
              </Typography>
            </Grid>

            {contract?.Payments?.map((payment) => (
              <>
                <Grid item xs={12} sm={8} key={payment.Id}>
                  Titular de la cuenta
                  <br />
                  <b>{payment?.AccountHolder ?? "Sin información"}</b>
                </Grid>

                <Grid item xs={12} sm={3}>
                  Tipo de pago
                  <br />
                  <b>
                    {payment?.PaymentCode === "01"
                      ? "Efectivo"
                      : payment?.PaymentCode === "02"
                      ? "Cheque nominativo"
                      : payment?.PaymentCode === "03"
                      ? "Transferencia electrónica de fondos"
                      : payment?.PaymentCode === "05"
                      ? "Tarjeta de débito"
                      : payment?.PaymentCode === "07"
                      ? "AMEX"
                      : "Tarjeta de crédito"}
                  </b>
                </Grid>

                <Grid item xs={12} sm={3}>
                  Banco
                  <br />
                  <b>
                    {banks?.find((item) => item.Code === payment.BankId)
                      ?.Name ?? "Sin información"}
                  </b>
                </Grid>

                <Grid item xs={12} sm={3}>
                  Monto pagado <br />
                  <b>{numberFormat(payment?.PaymentAmount ?? 0)}</b>
                </Grid>

                <Grid item xs={12} sm={3}>
                  Referencia <br />
                  <b>{payment?.Reference ?? "Sin información"}</b>
                </Grid>

                {contract?.CardsRegistry?.filter(
                  (cardData) => cardData.Id === payment.CardId
                )?.map((card) => (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    key={card.Id}
                    style={{ marginTop: "1rem" }}
                  >
                    <Paper elevation={3}>
                      <Grid
                        container
                        alignItems="flex-start"
                        justifyContent="center"
                        spacing={2}
                        style={{
                          padding: "1rem 0 1rem 1rem",
                          background: "#9cff9226",
                          margin: "0.5rem 0",
                        }}
                      >
                        <Grid item xs={12} sm={9}>
                          Titular de la tarjeta <br />
                          <b>{card?.CardHolder ?? ""}</b>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <Button
                            variant="outlined"
                            onClick={() => {
                              setOpenCardsDialog(true);
                              setCardId(card.Id);
                            }}
                            className={classes.margin}
                            startIcon={<EditIcon />}
                          >
                            Editar
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          Banco <br />
                          <b>
                            {banks?.find((item) => item.Code === card.BankId)
                              ?.Name ?? "Sin información"}
                          </b>
                        </Grid>

                        <Grid item xs={12} sm={2}>
                          Tipo de tarjeta <br />
                          <b>
                            {card?.CardType === "D"
                              ? "Débito"
                              : card.CardType === "C"
                              ? "Crédito"
                              : card.CardType === "A"
                              ? "AMEX"
                              : "Sin información"}
                          </b>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          Número de tarjeta <br />
                          <b>{card?.CardNumber ?? "Sin información"}</b>
                        </Grid>

                        <Grid item xs={12} sm={2}>
                          CVV <br />
                          <b>{card?.CVVCard ? "Oculto" : "Sin información"}</b>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          Vencimiento <br />
                          <b>
                            {card?.Expiration &&
                              (isValid(card?.Expiration)
                                ? format(card?.Expiration, "MM/yy")
                                : format(parseISO(card?.Expiration), "MM/yy"))}
                          </b>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                ))}
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.checkedF}
                        onChange={(event) => {
                          setState({
                            ...state,
                            [event.target.name]: event.target.checked,
                          });
                        }}
                        name="checkedF"
                        color="primary"
                      />
                    }
                    label={`Confirma los datos para el pago ${contract?.NumberPayments > 1 ? "del enganche" : "del contrato"}`}
                  />
                </Grid>
              </>
            ))}
          </Grid>
          {contract?.CardsRegistry?.filter((item) => item.PaymentType === "2")
            ?.length >= 1 && (
            <Grid
              container
              direction="row"
              alignItems="flex-start"
              justifyContent="center"
              spacing={2}
            >
              <Grid item xs={12} sm={12}>
                <Typography
                  variant="h5"
                  align="center"
                  color="textSecondary"
                  component="p"
                  style={{
                    backgroundImage:
                      "linear-gradient(to right, #0081bf, #00deff)",
                    color: "#fff",
                    margin: "4rem 0 2rem",
                    padding: "0.5rem",
                    boxShadow: "0.5rem 0.5rem .25rem #a0a0a0b8",
                    borderRadius: "0.5rem",
                  }}
                >
                  Forma de pago de las mensualidades
                </Typography>
              </Grid>
              {contract?.PaymentMethod?.U_GRUPO !== "C" && (
                <Grid item xs={12} sm={9}>
                  Fecha de primer vencimiento
                  <br />
                  <b>
                    {contract?.FirstPaymentAt &&
                      (isValid(contract?.FirstPaymentAt)
                        ? format(contract?.FirstPaymentAt, "dd/MM/yyyy")
                        : format(
                            parseISO(contract?.FirstPaymentAt),
                            "dd/MM/yyyy"
                          ))}
                  </b>
                </Grid>
              )}
              {contract?.PaymentMethod?.U_GRUPO !== "C" && (
                <Grid item xs={12} sm={3}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setOpenDateDialog(true);
                    }}
                    className={classes.margin}
                    startIcon={<EditIcon />}
                  >
                    Editar
                  </Button>
                </Grid>
              )}

              {contract?.CardsRegistry?.filter(
                (item) => item.PaymentType === "2"
              )?.map((card) => (
                <>
                  <Grid item xs={12} sm={8}>
                    Nombre <br />
                    <b>{card?.CardHolder ?? ""}</b>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setOpenCardsDialog(true);
                        setCardId(card.Id);
                      }}
                      className={classes.margin}
                      startIcon={<EditIcon />}
                    >
                      Editar
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    Banco <br />
                    <b>
                      {banks?.find((item) => item.Code === card.BankId)?.Name ??
                        "Sin información"}
                    </b>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    Tipo de tarjeta <br />
                    <b>
                      {card?.CardType === "C"
                        ? "Crédito"
                        : card?.CardType === "D"
                        ? "Débito"
                        : card?.CardType === "A"
                        ? "AMEX"
                        : "Sin información"}
                    </b>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    Número de tarjeta <br />
                    <b>
                      {card?.CardNumber}
                      {/* {card?.CardNumber.substring(
                        card?.CardNumber.length,
                        card?.CardNumber.length - 4
                      ) ?? "Sin información"} */}
                    </b>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    Vencimiento <br />
                    <b>
                      {card?.Expiration &&
                        (isValid(card?.Expiration)
                          ? format(card?.Expiration, "MM/yy")
                          : format(parseISO(card?.Expiration), "MM/yy"))}
                    </b>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.checkedB}
                          onChange={(event) => {
                            setState({
                              ...state,
                              [event.target.name]: event.target.checked,
                            });
                          }}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label="Confirma los datos para el pago de mensualidades"
                    />
                  </Grid>

                  {/*  <Grid item xs={12} sm={12}>
                  Tipo de pago <br />
                  <b>{payment?.PaymentCode === "01" ? "Efectivo" : payment?.PaymentCode === "02" ? "Cheque nominativo" :  payment?.PaymentCode === "03" ? "Transferencia electrónica de fondos" : "Tarjeta de credito" }</b>
                </Grid>
                

                <Grid item xs={12} sm={3}>
                  Titular de la cuenta<br />
                  <b>{payment?.AccountHolder ?? "Sin información"}</b>
                </Grid>
                <Grid item xs={12} sm={3}>
                  Banco <br />
                  <b>{payment?.BankName}</b>
                </Grid>

                <Grid item xs={12} sm={3}>
                  Monto pagado <br />
                  <b>${payment?.PaymentAmount ?? 0}</b>
                </Grid>

                <Grid item xs={12} sm={3}>
                  Referencia <br />
                  <b>{payment?.Reference ?? "Sin información"}</b>
                </Grid> */}
                </>
              ))}
            </Grid>
          )}

          {contract?.CardsRegistry?.filter((item) => item.PaymentType === "4")
            ?.length >= 1 && (
            <Grid
              container
              direction="row"
              alignItems="flex-start"
              justifyContent="center"
              spacing={2}
            >
              <Grid item xs={12} sm={12}>
                <Typography
                  variant="h5"
                  align="center"
                  color="textSecondary"
                  component="p"
                  style={{
                    backgroundImage:
                      "linear-gradient(to right,#bf9100, #ffc100)",
                    color: "#fff",
                    margin: "4rem 0 2rem",
                    padding: "0.5rem",
                    boxShadow: "0.5rem 0.5rem .25rem #a0a0a0b8",
                    borderRadius: "0.5rem",
                  }}
                >
                  Forma de pago de la asistencia para mascota
                </Typography>
              </Grid>

              {contract?.CardsRegistry?.filter(
                (item) => item.PaymentType === "4"
              )?.map((card) => (
                <>
                  <Grid item xs={12} sm={8}>
                    Nombre <br />
                    <b>{card?.CardHolder ?? ""}</b>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setOpenCardsDialog(true);
                        setCardId(card.CardId);
                      }}
                      className={classes.margin}
                      startIcon={<EditIcon />}
                    >
                      Editar
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    Banco <br />
                    <b>
                      {banks?.find((item) => item.Code === card.BankId)?.Name ??
                        "Sin información"}
                    </b>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    Tipo de tarjeta <br />
                    <b>{card?.CardTypeName ?? "Sin información"}</b>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    Número de tarjeta <br />
                    <b>{card?.CardNumber ?? "Sin información"}</b>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    Vencimiento <br />
                    <b>
                      {format(parseISO(card?.Expiration), "MM/yy", {
                        timeZone: "UTC",
                      }) ?? "Sin información"}
                    </b>
                  </Grid>
                </>
              ))}
            </Grid>
          )}

          {/* Footer */}

          <Grid
            container
            direction="row"
            alignItems="flex-start"
            justifyContent="center"
            spacing={2}
          >
            <Grid item xs={12} sm={12}>
              <Typography
                variant="h5"
                align="center"
                color="textSecondary"
                component="p"
                style={{
                  backgroundImage:
                    "linear-gradient(to right, rgb(0 59 119), #0367b7)",
                  color: "#fff",
                  margin: "4rem 0 2rem",
                  padding: "0.5rem",
                  boxShadow: "0.5rem 0.5rem .25rem #a0a0a0b8",
                  borderRadius: "0.5rem",
                }}
              >
                Aprobación
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              {/* <Typography
                variant="h6"
                align="left"
                color="textSecondary"
                component="p"
              >
                Si toda tu información esta completa, firma tu contra  y Si deseas cambiar algun dato de tu información 
              </Typography> */}
            </Grid>

            {calculateAge(contract.BirthDate) < 60 && (
              <Grid item xs={12} sm={12}>
                {contract.InsuranceApply === "1" ? (
                  <Alert severity="success">
                    <AlertTitle>Acepta</AlertTitle>
                    Seguro de vida, con valor de{" "}
                    <strong>${contract.InsuranceAmount}</strong>
                  </Alert>
                ) : (
                  <Alert severity="error">
                    <AlertTitle>Rechaza</AlertTitle>
                    <strong>Seguro de vida</strong>
                  </Alert>
                )}
              </Grid>
            )}

            <Grid item xs={12} sm={12}>
              {contract.Assistant ? (
                <Alert severity="success">
                  <AlertTitle>Acepta</AlertTitle>
                  <strong>Asistencia para mascota</strong>
                </Alert>
              ) : (contract.BusinessLine != 'BF') ? (
                <Alert severity="error">
                  <AlertTitle>Rechaza</AlertTitle>
                  <strong>Asistencia para mascota </strong>
                </Alert>
              ) : <></>}
            </Grid>

            <Grid item xs={12} sm={12}>
              <Alert severity="success">
                <AlertTitle>
                  Acepta CONTRATACIÓN POR MEDIOS ELECTRÓNICOS.
                </AlertTitle>
                <strong>
                  Las partes acuerdan que en lugar de una firma original
                  autógrafa, este contrato, así como cualquier consentimiento,
                  aprobación u otros documentos relacionados con el mismo,
                  podrán ser firmados por medio del uso de firmas electrónicas,
                  digitales, numéricas, alfanuméricas, huellas de voz,
                  biométricas o de cualquier otra forma y que dichos medios
                  alternativos de firma y los registros en donde sean aplicadas
                  dichas firmas, serán consideradas para todos los efectos,
                  incluyendo pero no limitado a la legislación civil, mercantil,
                  protección al consumidor y a la NOM-151-SCFI-2016, con la
                  misma fuerza y consecuencias que la firma autógrafa original
                  física de la parte firmante. Si el contrato o cualquier otro
                  documento relacionado con el mismo es firmado por medios
                  electrónicos o digitales, las Partes acuerdan que los formatos
                  del contrato y los demás documentos firmados de tal modo serán
                  conservados y estarán a disposición del consumidor, por lo que
                  convienen que cada una y toda la información enviada por el
                  Proveedor a la dirección de correo electrónico proporcionada
                  por el Consumidor al momento de celebrar el presente Contrato
                  será considerada como entregada en el momento en que la misma
                  es enviada, siempre y cuando exista confirmación de recepción.
                  <br></br><br></br>
                  Nuestros pagos son procesados mediante Openpay by BBVA.
                </strong>
              </Alert>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Alert
                severity={
                  state.checkedF ||
                  (contract?.PaymentMethod?.U_GRUPO !== "C" && state.checkedB)
                    ? "success"
                    : "error"
                }
              >
                <AlertTitle>
                  <b>
                    Para poder firmar su contrato debe confirmar la información de pago
                  </b>
                </AlertTitle>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.checkedF}
                      onChange={(event) => {
                        setState({
                          ...state,
                          [event.target.name]: event.target.checked,
                        });
                      }}
                      name="checkedF"
                      color={
                        state.checkedB && state.checkedF
                          ? "secondary"
                          : "primary"
                      }
                    />
                  }
                  label={`Confirma los datos para el pago ${contract?.NumberPayments > 1 ? "del enganche" : "del contrato"}`}
                />
                <br />
                {contract?.PaymentMethod?.U_GRUPO !== "C" && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.checkedB}
                        onChange={(event) => {
                          setState({
                            ...state,
                            [event.target.name]: event.target.checked,
                          });
                        }}
                        name="checkedB"
                        color={
                          state.checkedB && state.checkedF
                            ? "secondary"
                            : "primary"
                        }
                      />
                    }
                    label="Confirma los datos para el pago de mensualidades"
                  />
                )}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.checkedAcceptBilling}
                        onChange={(event) => {
                          setState({
                            ...state,
                            [event.target.name]: event.target.checked,
                          });
                        }}
                        name="checkedAcceptBilling"
                        color={
                          state.checkedAcceptBilling && state.checkedF
                            ? "secondary"
                            : "primary"
                        }
                      />
                    }
                    label="Una vez emitida la factura y transcurridas las 72 hora para aclaraciones, NO SE PODRÁ REALIZAR NINGUNA MODIFICACIÓN.
                    En caso de no proporcione sus datos fiscales, la factura será emitida con el RFC genérico XAXX010101000, como marcan las 
                    nuevas disposiciones. En este sentido, las aclaraciones solo podrán hacerse 72 horas posteriores a la emisión de su factura."
                  />
              </Alert>
            </Grid>
            <Grid item xs={12} sm={12}></Grid>
            {
            (contract?.PaymentMethod?.U_GRUPO !== "C" && state.checkedB && state.checkedF || contract?.PaymentMethod?.U_GRUPO === "C" && state.checkedF) && state.checkedAcceptBilling ? (
              <Grid item xs={12} sm={12}>
                {!imageURL || !success ? (
                  <>
                    <SignaturePad
                      ref={sigCanvas}
                      canvasProps={{
                        className: classes.signatureCanvas,
                      }}
                      clearOnResize={false}
                    />
                    <div style={{display: "flex"}}>
                      <ThemeProvider theme={theme}>
                        <div className={classes.wrapper}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={save}
                            style={{ color: "#fff" }}
                            className={buttonClassname}
                            disabled={loading}
                          >
                            Guardar Firma
                          </Button>
                          {loading && (
                            <CircularProgress
                              size={24}
                              className={classes.buttonProgress}
                            />
                          )}
                        </div>
                      </ThemeProvider>
                      <div className={classes.wrapper}>
                        <Button
                          variant="outlined"
                          onClick={clear}
                        >
                          Limpiar
                        </Button>
                        {loading && (
                          <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                          />
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {imageURL && success ? (
                  <>
                    <img
                      src={imageURL}
                      alt="my signature"
                      style={{
                        display: "block",
                        margin: "0 auto",
                        width: "150px",
                      }}
                    />
                    <Typography style={{ textAlign: "center" }}>
                      {contract.ContractHolder}
                    </Typography>
                  </>
                ) : null}
              </Grid>
            ) : (
              <></>
            )}
          </Grid>

          {/*  <Grid
          container
          spacing={4}
          justifyContent="space-evenly"
          className={classes.footer}
        >
          {footers.map((footer) => (
            <Grid item xs={12} sm={6} sm={3} key={footer.title}>
              <Typography variant="h6" color="textPrimary" gutterBottom>
                {footer.title}
              </Typography>
              <ul>
                {footer.description.map((item) => (
                  <li key={item}>
                    <Link href="#" variant="subtitle1" color="textSecondary">
                      {item}
                    </Link>
                  </li>
                ))}
              </ul>
            </Grid>
          ))}
        </Grid>
         */}
          <Box mt={5}>
            <Copyright />
          </Box>
        </Container>
      ) : contract.Status == 0 ? (
        <Container
          maxWidth="md"
          component="main"
          style={{ background: "#ffffff", width: "100%", height: "100vh" }}
        >
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
            style={{ padding: "4rem 0" }}
          >
            {contract.BusinessLine?.length > 1 ? (
              <img
                src={
                  contract.BusinessLine === "SG"
                    ? LogoSG
                    : contract.BusinessLine === "PF"
                    ? LogoPF
                    : LogoBF
                }
                alt="Privisión Final"
                style={{ width: "200px" }}
              />
            ) : (
              <></>
            )}
          </Grid>
          <Grid
            container
            spacing={2}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              variant="h5"
              align="center"
              color="textSecondary"
              component="p"
            >
              Contrato cancelado
            </Typography>
            <BlockIcon style={{ fontSize: 80, margin: 40, color: "#e82525" }} />
            <Typography
              variant="h6"
              align="center"
              color="textSecondary"
              component="p"
            >
              Solicite a su asesor {contract?.SalesPerson?.SlpName} un nuevo
              link para firmar su contrato.
            </Typography>
          </Grid>
        </Container>
      ) : !contract.Status && contract.contract && dataQuery.Coverage ? 
      (<Container
      maxWidth="md"
      component="main"
      style={{ background: "#ffffff", width: "100%", height: "100vh" }}
      >
        <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
            style={{ padding: "4rem 0" }}
          >
            {contract.contract.U_XAM_TIPOCON?.length > 1 ? (
              <img
                src={
                  contract.contract.U_XAM_TIPOCON === "SG"
                    ? LogoSG
                    : contract.contract.U_XAM_TIPOCON === "PF"
                    ? LogoPF
                    : LogoBF
                }
                alt="Privisión Final"
                style={{ width: "200px" }}
              />
            ) : (
              <></>
            )}
          </Grid>
          <Box sx={{ m: 2 }} />
          <Grid item xs={12} sm={12}>
          <Typography
              variant="h4"
              align="center"
              color="textSecondary"
              component="p"
            >
              Autorización para cargo con tarjeta de crédito/débito
            </Typography>
            <Box sx={{ m: 2 }} />
            <Typography
              variant="span"
              align="justify"
              color="textSecondary"
              component="p"
            >
              Estimado(a) <strong>{contract.contract.U_XAM_CARDNAME}</strong>
              <br/><br/>
              Solicitamos su autorización a través de su firma autógrafa para realizar un cargo de: <strong>{numberFormat(contract.coverage.U_XAM_DOCTOTAL)}</strong>, a su tarjeta terminación: <strong>{contract.contract.U_XAM_N2.substr(-4)}</strong>, por concepto de: <strong>Renovación coberturas y asistencias</strong>.
              </Typography>
          </Grid>
          <Box sx={{ m: 2 }} />
          <SignaturePad
            ref={sigCanvas}
            canvasProps={{
              className: classes.signatureCanvas,
            }}
            clearOnResize={false}
          />
          <div style={{display: "flex"}}>
            <ThemeProvider theme={theme}>
              <div className={classes.wrapper}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={saveSAP}
                  style={{ color: "#fff" }}
                  className={buttonClassname}
                  disabled={loading}
                >
                  Autorizo realizar el cargo a mi tarjeta
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </ThemeProvider>
            <div className={classes.wrapper}>
              <Button
                variant="outlined"
                onClick={clear}
              >
                Limpiar
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </div>
          <Box mt={5}>
            <Copyright />
          </Box>
      </Container>
      ) : !contract.Status && contract.contract && !contract.recordari && !contract.cession ? 
      (<Container
          maxWidth="md"
          component="main"
          style={{ background: "#ffffff", width: "100%" }}
        >
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
            style={{ padding: "4rem 0" }}
          >
            {contract.contract.U_XAM_TIPOCON?.length > 1 ? (
              <img
                src={
                  contract.contract.U_XAM_TIPOCON === "SG"
                    ? LogoSG
                    : contract.contract.U_XAM_TIPOCON === "PF"
                    ? LogoPF
                    : LogoBF
                }
                alt="Privisión Final"
                style={{ width: "200px" }}
              />
            ) : (
              <></>
            )}
          </Grid>
          <Box sx={{ m: 2 }} />
          <Button
            variant="contained"
            color="info"
            onClick={() => window.open(`${process.env.REACT_APP_PUBLIC_URL}/leader/${contract.filename}`)}
            style={{ color: "#000" }}
            className={buttonClassname}
            disabled={loading}
          >
            Ver póliza del seguro de vida Argos
          </Button>
          <Box sx={{ m: 2 }} />
          <Grid
              container
              direction="row"
              alignItems="flex-start"
              justifyContent="center"
              spacing={2}
            >
            <Grid item xs={12} sm={12}>
              <Typography
                variant="h5"
                align="center"
                color="textSecondary"
                component="p"
                style={{
                  backgroundImage:
                    "linear-gradient(to right, rgb(0 59 119), #0367b7)",
                  color: "#fff",
                  margin: " 0 0 2rem 0",
                  padding: "0.5rem",
                  boxShadow: "0.5rem 0.5rem .25rem #a0a0a0b8",
                  borderRadius: "0.5rem",
                }}
              >
                Información general
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              Nombre del titular <br />
              <b>
                {contract.contract.U_XAM_CARDNAME}
              </b>
            </Grid>
            <Grid item xs={4} sm={4}>
              Número de contrato <br />
              <b>
                {contract.contract.U_XAM_CONTRATO ?? " "}
              </b>
            </Grid>
            <Grid item xs={4} sm={4}>
              Fecha contratación <br />
              <b>
                {format(
                  parseISO(contract.contract.U_XAM_DOCDATE ?? new Date()),
                  "dd/MM/yyyy"
                )}
              </b>
            </Grid>
            <Grid item xs={4} sm={4}>
              Aseguradora <br />
              <b>
                Argos
              </b>
            </Grid>
            <Grid item xs={12} sm={12}>
              Suma asegurada <br />
              <b>
                {numberFormat(contract.contract.U_XAM_SUMASEG)}
              </b>
            </Grid>
          </Grid>
          <Box sx={{ m: 2 }} />
          <SignaturePad
            ref={sigCanvas}
            canvasProps={{
              className: classes.signatureCanvas,
            }}
            clearOnResize={false}
          />
          <div style={{display: "flex"}}>
            <ThemeProvider theme={theme}>
              <div className={classes.wrapper}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={saveSAP}
                  style={{ color: "#fff" }}
                  className={buttonClassname}
                  disabled={loading}
                >
                  Guardar Firma
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </ThemeProvider>
            <div className={classes.wrapper}>
              <Button
                variant="outlined"
                onClick={clear}
              >
                Limpiar
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </div>
          <Box mt={5}>
            <Copyright />
          </Box>
        </Container> 
      ) : !contract.Status && contract.contract && contract.recordari ? 
      (<Container
      maxWidth="md"
      component="main"
      style={{ background: "#ffffff", width: "100%", height: "100vh" }}
      >
        <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
            style={{ padding: "4rem 0" }}
          >
            {contract.contract.U_XAM_TIPOCON?.length > 1 ? (
              <img
                src={LogoRECORDARI}
                alt="Recordari"
                style={{ width: "600px" }}
              />
            ) : (
              <></>
            )}
          </Grid>
          <Box sx={{ m: 2 }} />
          <Grid item xs={12} sm={12}>
          <Typography
              variant="h4"
              align="center"
              color="textSecondary"
              component="p"
            >
              Autorización grabado para la personalización del nicho
            </Typography>
            <Box sx={{ m: 2 }} />
            <Typography
              variant="span"
              align="justify"
              color="textSecondary"
              component="p"
            >
              Estimado(a) <strong>{contract.contract.U_XAM_CARDNAME}</strong>
              <br/><br/>
              Sabemos que las memorias son esa conexión que tenemos con nuestros seres queridos, incluso sin han partido. Por ello, queremos agradecerle la confianza y oportunidad que nos ha brinado para hacer de <strong>Recordari</strong> ese lugar especial que le permita mantener cerca el recuerdo de las personas que tanto quiere.
              <br/><br/>
              Para honrar a sus seres queridos y personalizar su espacio, le agradecemos nos indique el o los apellidos con los que desee grabar el nicho <strong>Recordari</strong> ubicado en: <strong>{contract.recordari.Ubicacion} {contract.recordari.Coordenada}</strong>.
            </Typography>
          </Grid>
          <Box sx={{ m: 2 }} />
          <Grid item xs={12} sm={12}>
              <TextField
                required
                label="Escriba el texto deseado para grabar en el nicho"
                variant="filled"
                name="Plate"
                value={plate}
                fullWidth
                onChange={(event) => {
                  setPlate(event.target.value.toUpperCase());
                }}
              />
            </Grid>
          <Box sx={{ m: 2 }} />
          <SignaturePad
            ref={sigCanvas}
            canvasProps={{
              className: classes.signatureCanvas,
            }}
            clearOnResize={false}
          />
          <div style={{display: "flex"}}>
            <ThemeProvider theme={theme}>
              <div className={classes.wrapper}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={saveSAPPlate}
                  style={{ color: "#fff" }}
                  className={buttonClassname}
                  disabled={loading}
                >
                  Guardar placa y firmar
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </ThemeProvider>
            <div className={classes.wrapper}>
              <Button
                variant="outlined"
                onClick={clear}
              >
                Limpiar
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </div>
          <Box mt={5}>
            <Copyright />
          </Box>
      </Container>
      ) : contract.contract && contract.cession ? 
      (<Container
      maxWidth="md"
      component="main"
      style={{ background: "#ffffff", width: "100%", height: "100vh" }}
      >
        <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
            style={{ padding: "4rem 0" }}
          >
            {contract.contract.U_XAM_TIPOCON?.length > 1 ? (
              <img
                src={
                  contract.contract.U_XAM_TIPOCON === "SG"
                    ? LogoSG
                    : contract.contract.U_XAM_TIPOCON === "PF"
                    ? LogoPF
                    : LogoBF
                }
                alt="J. García López"
                style={{ width: "200px" }}
              />
            ) : (
              <></>
            )}
          </Grid>
          <Box sx={{ m: 2 }} />
          <Grid item xs={12} sm={12}>
          <Typography
              variant="h4"
              align="center"
              color="textSecondary"
              component="p"
            >
              Autorización para cesión de derechos de contrato
            </Typography>
            <Box sx={{ m: 2 }} />
          </Grid>
          <Box sx={{ m: 2 }} />
          <SignaturePad
            ref={sigCanvas}
            canvasProps={{
              className: classes.signatureCanvas,
            }}
            clearOnResize={false}
          />
          <div style={{display: "flex"}}>
            <ThemeProvider theme={theme}>
              <div className={classes.wrapper}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={saveSAPCession}
                  style={{ color: "#fff" }}
                  className={buttonClassname}
                  disabled={loading}
                >
                  Guardar y firmar
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </ThemeProvider>
            <div className={classes.wrapper}>
              <Button
                variant="outlined"
                onClick={clear}
              >
                Limpiar
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </div>
          <Box mt={5}>
            <Copyright />
          </Box>
      </Container>
      ) : (
        <Container
          maxWidth="md"
          component="main"
          style={{ background: "#ffffff", width: "100%", height: "100vh" }}
        >
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
            style={{ padding: "4rem 0" }}
          >
            {contract.BusinessLine?.length > 1 ? (
              <img
                src={
                  contract.BusinessLine === "SG"
                    ? LogoSG
                    : contract.BusinessLine === "PF"
                    ? LogoPF
                    : LogoBF
                }
                alt="Privisión Final"
                style={{ width: "200px" }}
              />
            ) : (
              <></>
            )}
          </Grid>
          <Grid
            container
            spacing={2}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              variant="h5"
              align="center"
              color="textSecondary"
              component="p"
            >
              Contrato con un estatus diferente
            </Typography>
            <AssignmentLateIcon
              style={{ fontSize: 80, margin: 40, color: "#5dc76a" }}
            />
            <Typography
              variant="h6"
              align="center"
              color="textSecondary"
              component="p"
            ></Typography>
          </Grid>
        </Container>
      )}
      {/* End footer */}
    </React.Fragment>
  );
}
