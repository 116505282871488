import React, { useState, useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { isValid, parseISO, add, format } from "date-fns";
import esLocale from "date-fns/locale/es";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

function ClientEdit(props) {
  const classes = useStyles();
  const {
    loading,
    successButton,
    inputVariant,
    states,
    banks,
    setContract,
    active,
    contract,
    setOpenDialog,
    setOpenCardsDialog,
    setOpenBeneficiaryDialog,
    openDialog,
    openCardsDialog,
    openBeneficiaryDialog,
    zipCodeExistence,
    beneficiaryId,
    cardId,
    openDateDialog,
    setOpenDateDialog,
    openSignConfirmation,
    setOpenSignConfirmation,
    save,
    token,
    taxRegimeName
  } = props;

  const [suburbs, setSuburbs] = useState([]);
  const [dataZipCode, setDataZipCode] = useState({});
  const relations = [
    "MADRE",
    "PADRE",
    "ESPOSO",
    "ESPOSA",
    "CONYUGE",
    "CONCUBINO",
    "CONCUBINA",
    "PAREJA",
    "TIO",
    "TIA",
    "HERMANA",
    "HERMANO",
    "ABUELO",
    "ABUELA",
    "NIETO",
    "NIETA",
    "HIJO",
    "HIJA",
    "SOBRINO",
    "SOBRINA",
    "AMIGO",
    "AMIGA",
    "PRIMO",
    "PRIMA",
  ];
  const optionsType = [
    { Code: "C", Name: "Crédito" },
    { Code: "D", Name: "Débito" },
    { Code: "A", Name: "AMEX" },
  ];

  const [taxRegimes, setTaxRegimes] = useState( [] );
  const [taxRegime, setTaxRegime] = useState( { Code: contract.TaxRegime, Name: taxRegimeName } );
  
  useEffect(() => {
    fetchTaxRegimes();
  }, []);


  let myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  let requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  const buttonClassname = clsx({
    [classes.buttonSuccess]: successButton,
  });

  const handleChangeAddress = (event) => {
    let address = {
      ...contract?.Addresses?.find(
        (address) => address.Id == contract.AddressId
      ),
      [event.target.name]: event.target.value.toUpperCase(),
    };
    let addresses = contract?.Addresses?.filter(
      (address) => address.Id != contract.AddressId
    );
    setContract({
      ...contract,
      ...{ Addresses: addresses.concat(address) },
    });
  };
  const handleChangeRelationshipBeneficiary = (event, value) => {
    let relationship;
    if (value) {
      relationship = contract?.Beneficiaries?.find(
        (beneficiary) => beneficiary.Id == beneficiaryId
      )?.Relationship
        ? {
            ...contract?.Beneficiaries?.find(
              (beneficiary) => beneficiary.Id == beneficiaryId
            ),
            ...{ Relationship: value },
          }
        : {
            ...contract?.Beneficiaries?.find(
              (beneficiary) => beneficiary.Id == beneficiaryId
            ),
            Relationship: value,
          };
    } else {
      relationship = contract?.Beneficiaries?.find(
        (beneficiary) => beneficiary.Id == beneficiaryId
      )?.relationship
        ? {
            ...contract?.Beneficiaries?.find(
              (beneficiary) => beneficiary.Id == beneficiaryId
            ),
            [event.target.name]: event.target.value,
          }
        : {
            ...contract?.Beneficiaries?.find(
              (beneficiary) => beneficiary.Id == beneficiaryId
            ),

            [event.target.name]: event.target.value,
          };
    }

    let beneficiaries = contract?.Beneficiaries?.filter(
      (beneficiary) => beneficiary.Id != beneficiaryId
    );
    setContract({
      ...contract,
      ...{ Beneficiaries: beneficiaries.concat(relationship) },
    });
  };

  const handleChangeAddressBeneficiary = (event, value) => {
    let address;

    if (value) {
      address = contract?.Beneficiaries?.find(
        (beneficiary) => beneficiary.Id == beneficiaryId
      )?.Address
        ? {
            ...contract?.Beneficiaries?.find(
              (beneficiary) => beneficiary.Id == beneficiaryId
            )?.Address[0],
            ...{ Suburb: value },
          }
        : {
            ...contract?.Beneficiaries?.find(
              (beneficiary) => beneficiary.Id == beneficiaryId
            ),
            Address: [{ Suburb: value }],
          };
    } else {
      address = contract?.Beneficiaries?.find(
        (beneficiary) => beneficiary.Id == beneficiaryId
      )?.Address
        ? {
            ...contract?.Beneficiaries?.find(
              (beneficiary) => beneficiary.Id == beneficiaryId
            )?.Address[0],
            ...{ [event.target.name]: event.target.value.toUpperCase() },
          }
        : {
            ...contract?.Beneficiaries?.find(
              (beneficiary) => beneficiary.Id == beneficiaryId
            ),
            Address: [
              { [event.target.name]: event.target.value.toUpperCase() },
            ],
          };
    }
    let addressContent = [address];

    let beneficiary = {
      ...contract?.Beneficiaries?.find(
        (beneficiary) => beneficiary.Id == beneficiaryId
      ),
      ...{ Address: addressContent },
    };
    let beneficiaries = contract?.Beneficiaries?.filter(
      (beneficiary) => beneficiary.Id != beneficiaryId
    );
    setContract({
      ...contract,
      ...{ Beneficiaries: beneficiaries.concat(beneficiary) },
    });
  };

  const handleChangeBeneficiary = (event) => {
    let beneficiary = {
      ...contract?.Beneficiaries?.find(
        (beneficiary) => beneficiary.Id == beneficiaryId
      ),
      [event.target.name]: event.target.value.toUpperCase(),
    };
    let beneficiaries =
      contract?.Beneficiaries?.filter(
        (beneficiary) => beneficiary.Id != beneficiaryId
      ) ?? [];
    setContract({
      ...contract,
      ...{ Beneficiaries: beneficiaries.concat(beneficiary) },
    });
  };
  const handleChangeBank = (event, value) => {
    let card;
    if (value) {
      card = {
        ...contract?.CardsRegistry?.find((card) => card.Id == cardId),
        BankId: value.Code,
        CardType:
          value.Code == 6
            ? "A"
            : contract?.CardsRegistry?.find((card) => card.Id == cardId)
                .CardType === "A"
            ? ""
            : contract?.CardsRegistry?.find((card) => card.Id == cardId)
                .CardType,
      };
    } else {
      card = {
        ...contract?.CardsRegistry?.find((card) => card.Id == cardId),
        [event.target.name]: event.target.value.toUpperCase(),
      };
    }
    let cards =
      contract?.CardsRegistry?.filter((card) => card.Id != cardId) ?? [];
    setContract({
      ...contract,
      ...{ CardsRegistry: cards.concat(card) },
    });
  };

  const handleChangeCard = (event, value) => {
    let card;
    if (value) {
      card = {
        ...contract?.CardsRegistry?.find((card) => card.Id == cardId),
        CardType: value.Code,
        BankId:
          value.Code === "A"
            ? 6
            : contract?.CardsRegistry?.find((card) => card.Id == cardId)
                .BankId == 6
            ? 0
            : contract?.CardsRegistry?.find((card) => card.Id == cardId).BankId,
      };
    } else {
      card = {
        ...contract?.CardsRegistry?.find((card) => card.Id == cardId),
        [event.target.name]: event.target.value.toUpperCase(),
      };
    }
    let cards =
      contract?.CardsRegistry?.filter((card) => card.Id != cardId) ?? [];
    setContract({
      ...contract,
      ...{ CardsRegistry: cards.concat(card) },
    });
  };

  const getZipCodeData = async (event) => {
    if (event.target.value?.length === 5) {
      fetch(
        `https://api.copomex.com/query/info_cp/${event.target.value}?type=simplified&token=4e2b8742-6d14-4f4b-a341-9c83ea8f6f00`,
        requestOptions
      )
        .then((response) => response.json())
        .then(async (response) => {
          var data = response.response;
          if (!data.error) {
            if (data.estado === "México") {
              data.estado = "Estado de México";
            } else if (data.estado === "Veracruz de Ignacio de la Llave") {
              data.estado = "Veracruz";
            } else if (data.estado === "Coahuila de Zaragoza") {
              data.estado = "Coahuila";
            } else if (data.estado === "Michoacán de Ocampo") {
              data.estado = "Michoacán";
            }
            setSuburbs(data.asentamiento);

            const addressPayload = {
              ZipCode: data.cp,
              Suburb: data.asentamiento[0],
              County: data.municipio,
              City: data.ciudad,
              StateCode: await states.find(
                (state) => state.Name === data.estado
              )?.Code,
              StateName: await states.find(
                (state) => state.Name === data.estado
              )?.Name,
            };

            let address = {
              ...contract?.Addresses?.find(
                (address) => address.Id == contract.AddressId
              ),
              ...addressPayload,
            };

            let addresses = contract?.Addresses?.filter(
              (address) => address.Id != contract.AddressId
            );
            await setContract({
              ...contract,
              ...{ Addresses: addresses.concat(address) },
            });
            return data;
          } else {
            return;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };
  const getZipCodeDataBeneficiary = async (event) => {
    if (event.target.value?.length === 5) {
      fetch(
        `https://api.copomex.com/query/info_cp/${event.target.value}?type=simplified&token=4e2b8742-6d14-4f4b-a341-9c83ea8f6f00`,
        requestOptions
      )
        .then((response) => response.json())
        .then(async (response) => {
          var data = response.response;
          if (!data.error) {
            if (data.estado === "México") {
              data.estado = "Estado de México";
            } else if (data.estado === "Veracruz de Ignacio de la Llave") {
              data.estado = "Veracruz";
            } else if (data.estado === "Coahuila de Zaragoza") {
              data.estado = "Coahuila";
            } else if (data.estado === "Michoacán de Ocampo") {
              data.estado = "Michoacán";
            }
            setSuburbs(data.asentamiento);

            const addressPayload = {
              ZipCode: data.cp,
              Suburb: data.asentamiento[0],
              County: data.municipio,
              City: data.ciudad,
              StateCode: await states.find(
                (state) => state.Name === data.estado
              )?.Code,
              StateName: await states.find(
                (state) => state.Name === data.estado
              )?.Name,
            };

            let beneficiary = contract?.Beneficiaries?.find(
              (beneficiary) => beneficiary.Id == beneficiaryId
            );
            let newBeneficiary = {
              ...beneficiary,
              ...{ Address: [addressPayload] },
            };

            let beneficiaries =
              contract?.Beneficiaries?.filter(
                (beneficiary) => beneficiary.Id != beneficiaryId
              ) ?? [];
            setContract({
              ...contract,
              ...{ Beneficiaries: beneficiaries.concat(newBeneficiary) },
            });
            return data;
          } else {
            return;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const fetchTaxRegimes = (fedReg, blnChange = false) => {
    if (!fedReg) {
      fedReg = contract.InvoiceFederalReg ?? "";
    }
    var taxType = fedReg.length > 0 ? (fedReg.length == 12 ? 'M' : 'F') : 'U';
    
    fetch( `${process.env.REACT_APP_PUBLIC_URL}/catalog/${taxType}/tax-regime`, requestOptions )
      .then( (response) => response.json() )
      .then( (result) => {
          setTaxRegimes( result.body );
          if (blnChange) {
            setTaxRegime( null );
          }
      } );

  };


  return (
    <>

      <Dialog
        open={openDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Editar</DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{
              color: "#00309c",
              textTransform: "uppercase",
              fontWeight: "500",
            }}
          >
            Datos del titular
          </DialogContentText>
          <Grid container direction="row" alignItems="flex-start" spacing={1}>
            <Grid item xs={12} sm={4}>
              <TextField
                required
                variant={inputVariant}
                label="Nombre"
                className={contract && !contract.Name ? active : ""}
                name="Name"
                value={contract.Name}
                onChange={(event) => {
                  setContract({
                    ...contract,
                    [event.target.name]: event.target.value.toUpperCase(),
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                required
                variant={inputVariant}
                label="Apellido paterno"
                className={contract && !contract.LastName ? active : ""}
                name="LastName"
                value={contract.LastName}
                onChange={(event) => {
                  setContract({
                    ...contract,
                    [event.target.name]: event.target.value.toUpperCase(),
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                required
                variant={inputVariant}
                label="Apellido materno"
                className={contract && !contract.NumberCard ? active : ""}
                name="MotherLastName"
                value={contract.MotherLastName}
                onChange={(event) => {
                  setContract({
                    ...contract,
                    [event.target.name]: event.target.value.toUpperCase(),
                  });
                }}
              />
            </Grid>

            <Grid item xs={6} sm={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <KeyboardDatePicker
                  required
                  fullWidth
                  autoOk
                  variant="inline"
                  inputVariant={inputVariant}
                  label="Fecha de nacimiento"
                  format="dd/MM/yyyy"
                  InputAdornmentProps={{ position: "end" }}
                  onChange={(date) => {
                    if (isValid(date)) {
                      let birthdate = format(date, "yyy-MM-dd").toString();
                      setContract({ ...contract, BirthDate: birthdate });
                    }
                  }}
                  disableFuture
                  className={contract && !contract.BirthDate ? active : ""}
                  name="BirthDate"
                  value={parseISO(contract.BirthDate)}
                  minDate={new Date("1800")}
                  maxDate={new Date()}
                  views={["year", "month", "date"]}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={9} sm={6}>
              <Autocomplete
                name="BirthPlace"
                options={states}
                getOptionLabel={(option) => `${option.Name}`}
                style={{ width: "100%" }}
                value={
                  states.length > 0 &&
                  states.find((option) => option.Code === contract.BirthPlace)
                }
                onChange={(event, value) => {
                  setContract({ ...contract, BirthPlace: value.Code });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label="Lugar de nacimiento"
                    variant={inputVariant}
                    className={contract && !contract.BirthPlace ? active : ""}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                required
                variant={inputVariant}
                label="CURP"
                name="PopulationReg"
                value={contract?.PopulationReg ?? ""}
                onChange={(event) => {
                  setContract({
                    ...contract,
                    [event.target.name]: event.target.value.toUpperCase(),
                  });
                }}
                className={contract && !contract.PopulationReg && active}
                fullWidth
                inputProps={{
                  maxLength: 18,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                variant={inputVariant}
                label="RFC"
                name="FederalReg"
                value={contract.FederalReg && contract.FederalReg}
                onChange={(event) => {
                  setContract({
                    ...contract,
                    [event.target.name]: event.target.value.toUpperCase(),
                  });
                }}
                className={contract && !contract.FederalReg && active}
                fullWidth
                inputProps={{
                  maxLength: 13,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Genero</FormLabel>
                <RadioGroup
                  aria-label="Gender"
                  name="Gender"
                  value={contract && contract.Gender ? contract.Gender : ""}
                  onChange={(event) => {
                    setContract({
                      ...contract,
                      [event.target.name]: event.target.value.toUpperCase(),
                    });
                  }}
                  style={{ flexDirection: "row" }}
                >
                  <FormControlLabel
                    value="F"
                    control={<Radio />}
                    label="Femenino"
                  />
                  <FormControlLabel
                    value="M"
                    control={<Radio />}
                    label="Masculino"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>

          <DialogContentText
            style={{
              color: "#00309c",
              textTransform: "uppercase",
              fontWeight: "500",
              margin: "1rem 0",
            }}
          >
            Contacto
          </DialogContentText>

          <Grid container direction="row" alignItems="flex-start" spacing={1}>
            <Grid item xs={12} sm={6}>
              <TextField
                variant={inputVariant}
                label="Correo electrónico"
                onChange={(event) => {
                  setContract({
                    ...contract,
                    [event.target.name]: event.target.value,
                  });
                }}
                className={contract && !contract.Email && active}
                name="Email"
                value={contract.Email}
                fullWidth
                error={
                  contract.Email &&
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                    contract.Email
                  )
                }
                helperText={
                  contract.Email &&
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                    contract.Email
                  )
                    ? "Ingrese un correo electrónico valido"
                    : ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                variant={inputVariant}
                label="Celular"
                onChange={(event) => {
                  setContract({
                    ...contract,
                    [event.target.name]: event.target.value.toUpperCase(),
                  });
                }}
                className={contract && !contract.MobilePhone && active}
                name="MobilePhone"
                value={contract.MobilePhone}
                error={
                  contract?.MobilePhone && contract?.MobilePhone?.length !== 10
                }
                helperText={
                  contract?.MobilePhone &&
                  contract.MobilePhone?.length !== 10 &&
                  "Ingrese un número de teléfono valido"
                }
                inputProps={{
                  maxLength: 10,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                variant={inputVariant}
                label="Teléfono de casa"
                onChange={(event) => {
                  setContract({
                    ...contract,
                    [event.target.name]: event.target.value.toUpperCase(),
                  });
                }}
                className={contract && !contract.HomePhone && active}
                name="HomePhone"
                value={contract.HomePhone}
                error={
                  contract?.HoomePhone && contract.HomePhone?.length !== 10
                }
                helperText={
                  contract.HomePhone &&
                  contract.HomePhone?.length !== 10 &&
                  "Ingrese un número de teléfono valido"
                }
                inputProps={{
                  maxLength: 10,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                variant={inputVariant}
                label="Teléfono de oficina"
                onChange={(event) => {
                  setContract({
                    ...contract,
                    [event.target.name]: event.target.value.toUpperCase(),
                  });
                }}
                className={contract && !contract.OfficePhone && active}
                name="OfficePhone"
                value={contract.OfficePhone}
                error={
                  contract?.OfficePhone && contract?.OfficePhone?.length !== 10
                }
                helperText={
                  contract?.OfficePhone &&
                  contract?.OfficePhone?.length !== 10 &&
                  "Ingrese un número de teléfono valido"
                }
                inputProps={{
                  maxLength: 10,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                variant={inputVariant}
                label="Extensión"
                onChange={(event) => {
                  setContract({
                    ...contract,
                    [event.target.name]: event.target.value.toUpperCase(),
                  });
                }}
                className={contract && !contract.OfficePhoneExt && active}
                name="OfficePhoneExt"
                value={contract.OfficePhoneExt}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                variant={inputVariant}
                label="Otro"
                onChange={(event) => {
                  setContract({
                    ...contract,
                    [event.target.name]: event.target.value.toUpperCase(),
                  });
                }}
                className={contract && !contract.Other && active}
                name="Other"
                value={contract.Other}
              />
            </Grid>
          </Grid>

          <DialogContentText
            style={{
              color: "#00309c",
              textTransform: "uppercase",
              fontWeight: "500",
              margin: "1rem 0",
            }}
          >
            Dirección
          </DialogContentText>

          <Grid container direction="row" alignItems="flex-start" spacing={1}>
            <Grid item xs={12} sm={3}>
              <TextField
                name="ZipCode"
                variant={inputVariant}
                label="Código postal"
                value={
                  contract?.Addresses?.find(
                    (address) => address.Id == contract.AddressId
                  )?.ZipCode ?? ""
                }
                onChange={(event) => {
                  handleChangeAddress(event);
                  getZipCodeData(event);
                }}
                fullWidth
                inputProps={{
                  maxLength: 5,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={9}>
              <TextField
                name="Street"
                variant={inputVariant}
                label="Calle"
                onChange={handleChangeAddress}
                value={
                  contract?.Addresses?.find(
                    (address) => address.Id == contract.AddressId
                  )?.Street ?? ""
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                name="StreetNo"
                variant={inputVariant}
                label="N° Exterior"
                onChange={handleChangeAddress}
                value={
                  contract?.Addresses?.find(
                    (address) => address.Id == contract.AddressId
                  )?.StreetNo ?? ""
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                name="StreetNoInt"
                variant={inputVariant}
                label="N° Interior"
                onChange={handleChangeAddress}
                value={
                  contract?.Addresses?.find(
                    (address) => address.Id == contract.AddressId
                  )?.StreetNoInt ?? ""
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <Autocomplete
                name="Suburb"
                options={suburbs}
                freeSolo
                getOptionLabel={(option) => `${option}`}
                style={{ width: "100%" }}
                value={
                  contract?.Addresses?.find(
                    (address) => address.Id == contract.AddressId
                  )?.Suburb
                }
                onChange={handleChangeAddress}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Colonia"
                    name="Suburb"
                    variant={inputVariant}
                    onChange={handleChangeAddress}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                name="County"
                variant={inputVariant}
                label="Delegación/Alcaldia"
                value={
                  contract?.Addresses?.find(
                    (address) => address.Id == contract.AddressId
                  )?.County ?? ""
                    ? contract?.Addresses?.find(
                        (address) => address.Id == contract.AddressId
                      )?.County ?? ""
                    : ""
                }
                onChange={handleChangeAddress}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                name="City"
                variant={inputVariant}
                label="Ciudad"
                value={
                  contract?.Addresses?.find(
                    (address) => address.Id == contract.AddressId
                  )?.City ?? ""
                    ? contract?.Addresses?.find(
                        (address) => address.Id == contract.AddressId
                      )?.City ?? ""
                    : ""
                }
                onChange={handleChangeAddress}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Autocomplete
                name="StateCode"
                options={states}
                getOptionLabel={(option) => `${option.Name ?? ""}`}
                style={{ width: "100%" }}
                value={
                  states.length > 0 &&
                  states.find(
                    (state) =>
                      state.Code ===
                        contract?.Addresses?.find(
                          (address) => address.Id == contract.AddressId
                        )?.StateCode ?? ""
                  ) !== undefined
                    ? states.find(
                        (state) =>
                          state.Code ===
                            contract?.Addresses?.find(
                              (address) => address.Id == contract.AddressId
                            )?.StateCode ?? ""
                      )
                    : ""
                }
                onChange={props.handleStateChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Estado"
                    variant={inputVariant}
                  />
                )}
              />
              {/* <TextFi
          name="StateCode"
          variant={inputVariant
          label={intl.formatMessage({
            id: "INPUT.STATE",
          })}
          value={
            states.length > 0 &&
            states.find((state) => state.Code === contract?.StateCode ?? "") !==
              undefined
              ? states.find((state) => state.Code === contract?.StateCode ?? "")
                  .Name
              : ""
          }
        /> */}
            </Grid>
          </Grid>
          <DialogContentText
            style={{
              color: "#00309c",
              textTransform: "uppercase",
              fontWeight: "500",
              margin: "1rem 0",
            }}
          >
            Datos de facturación
          </DialogContentText>

          <Grid container direction="row" alignItems="flex-start" spacing={1}>
            <Grid item xs={12} sm={12}>
              <TextField
                name="InvoiceCustomerName"
                variant={inputVariant}
                label={
                  contract.InvoiceFederalReg?.length == 13
                    ? "Nombre persona física"
                    : "Razón social"
                }
                value={contract?.InvoiceCustomerName}
                onChange={(event) => {
                  setContract({
                    ...contract,
                    [event.target.name]: event.target.value.toUpperCase(),
                  });
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="InvoiceFederalReg"
                variant={inputVariant}
                label="RFC"
                onChange={(event) => {
                  setContract({
                    ...contract,
                    [event.target.name]: event.target.value.toUpperCase(),
                  });
                  fetchTaxRegimes( event.target.value, true );
                }}
                value={contract?.InvoiceFederalReg}
                fullWidth
                inputProps={{
                  maxLength: 13,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="InvoicedEmail"
                variant={inputVariant}
                label="Correo electrónico"
                onChange={(event) => {
                  setContract({
                    ...contract,
                    [event.target.name]: event.target.value,
                  });
                }}
                value={contract?.InvoicedEmail}
                fullWidth
              />
            </Grid>


            <Grid item xs={12} md={6}>
            <Autocomplete
              name="TaxRegime"
              options={taxRegimes}
              getOptionLabel={(option) => `${option.Code} - ${option.Name}`} 
              style={{ width: "100%" }}
              value={taxRegime}
              onChange={ (event, value) => {
                if (value && value.Code) {
                  setContract({
                    ...contract,
                    TaxRegime: `${value.Code}`,
                  });
                  setTaxRegime( { Code: value.Code, Name: value.Name } );
                } else {
                  setContract({
                    ...contract,
                    [event.target.name]: event.target.value,
                  });
  
                }
              }}
              color="primary" 
              renderInput={(params) => (
                <TextField {...params} label="Régimen Fiscal" variant={inputVariant} required />
              )}
            />
          </Grid>
         
          <Grid item xs={12} sm={6}>
            <TextField
              required
              variant={inputVariant}
              label="Codigo Postal"
              onChange={(event) => {
                setContract({
                  ...contract,
                  [event.target.name]: event.target.value,
                });
              }}
              className={contract && !contract.TaxZipCode && active}
              name="TaxZipCode"
              value={contract && contract.TaxZipCode}
            />
          </Grid>






          </Grid>
        </DialogContent>
        <DialogActions>
        <div className={classes.wrapper}>
          <Button
            onClick={() => {
              setOpenDialog(false);
            }}
            color="primary"
            disabled={loading}
          >
            Cancelar
          </Button>
          {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>


          <div className={classes.wrapper}>
            <Button
              onClick={props.handleSaveData}
              variant="contained"
              color="primary"
              className={buttonClassname}
              disabled={loading}
            >
              Guardar
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
          
        </DialogActions>
      </Dialog>
      <Dialog
        open={openBeneficiaryDialog}
        aria-labelledby="form-dialog-cards"
      >
        <DialogTitle id="form-dialog-cards">Editar</DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{
              color: "#00309c",
              textTransform: "uppercase",
              fontWeight: "500",
            }}
          >
            Datos de beneficiario
          </DialogContentText>

          <Grid container direction="row" alignItems="flex-start" spacing={1}>
            <Grid item xs={12} sm={4}>
              <TextField
                required
                variant={inputVariant}
                label="Nombre"
                className={contract && !contract.Name ? active : ""}
                name="Name"
                value={
                  contract?.Beneficiaries?.find(
                    (beneficiary) => beneficiary.Id == beneficiaryId
                  )?.Name
                }
                onChange={(event) => {
                  handleChangeBeneficiary(event);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                required
                variant={inputVariant}
                label="Apellido paterno"
                className={contract && !contract.LastName ? active : ""}
                name="LastName"
                value={
                  contract?.Beneficiaries?.find(
                    (beneficiary) => beneficiary.Id == beneficiaryId
                  )?.LastName
                }
                onChange={(event) => {
                  handleChangeBeneficiary(event);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                required
                variant={inputVariant}
                label="Apellido materno"
                className={contract && !contract.MotherLastName ? active : ""}
                name="MotherLastName"
                value={
                  contract?.Beneficiaries?.find(
                    (beneficiary) => beneficiary.Id == beneficiaryId
                  )?.MotherLastName
                }
                onChange={(event) => {
                  handleChangeBeneficiary(event);
                }}
              />
            </Grid>

            <Grid item xs={6} sm={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <KeyboardDatePicker
                  required
                  fullWidth
                  autoOk
                  variant="inline"
                  inputVariant={inputVariant}
                  label="Fecha de nacimiento"
                  format="dd/MM/yyyy"
                  InputAdornmentProps={{ position: "end" }}
                  onChange={(date) => {
                    if (isValid(date)) {
                      let birthdate = format(date, "yyy-MM-dd").toString();
                      let beneficiary = {
                        ...contract?.Beneficiaries?.find(
                          (beneficiary) => beneficiary.Id == beneficiaryId
                        ),
                        BirthDate: birthdate,
                      };
                      let beneficiaries =
                        contract?.Beneficiaries?.filter(
                          (beneficiary) => beneficiary.Id != beneficiaryId
                        ) ?? [];
                      setContract({
                        ...contract,
                        ...{ Beneficiaries: beneficiaries.concat(beneficiary) },
                      });
                    }
                  }}
                  disableFuture
                  className={contract && !contract.BirthDate ? active : ""}
                  name="BirthDate"
                  value={parseISO(
                    contract?.Beneficiaries?.find(
                      (beneficiary) => beneficiary.Id == beneficiaryId
                    )?.BirthDate
                  )}
                  minDate={new Date("1800")}
                  maxDate={new Date()}
                  views={["year", "month", "date"]}
                  /* helperText={
                (contract?.Age > 69 || contract?.Age < 18) &&
                "La edad no es permitida para el seguro de vida"
              } */
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={6}></Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant={inputVariant}
                label="Correo electrónico"
                onChange={(event) => {
                  let beneficiary = {
                    ...contract?.Beneficiaries?.find(
                      (beneficiary) => beneficiary.Id == beneficiaryId
                    ),
                    [event.target.name]: event.target.value,
                  };
                  let beneficiaries =
                    contract?.Beneficiaries?.filter(
                      (beneficiary) => beneficiary.Id != beneficiaryId
                    ) ?? [];
                  setContract({
                    ...contract,
                    ...{ Beneficiaries: beneficiaries.concat(beneficiary) },
                  });
                }}
                className={contract && !contract.Email && active}
                name="Email"
                value={
                  contract?.Beneficiaries?.find(
                    (beneficiary) => beneficiary.Id == beneficiaryId
                  )?.Email
                }
                fullWidth
                error={
                  contract?.Beneficiaries?.find(
                    (beneficiary) => beneficiary.Id == beneficiaryId
                  )?.Email &&
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                    contract?.Beneficiaries?.find(
                      (beneficiary) => beneficiary.Id == beneficiaryId
                    )?.Email
                  )
                }
                helperText={
                  contract?.Beneficiaries?.find(
                    (beneficiary) => beneficiary.Id == beneficiaryId
                  )?.Email &&
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                    contract?.Beneficiaries?.find(
                      (beneficiary) => beneficiary.Id == beneficiaryId
                    )?.Email
                  )
                    ? "Ingrese un correo electrónico valido"
                    : ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                variant={inputVariant}
                label="Celular"
                onChange={(event) => {
                  handleChangeBeneficiary(event);
                }}
                className={contract && !contract.MobilePhone && active}
                name="MobilePhone"
                value={
                  contract?.Beneficiaries?.find(
                    (beneficiary) => beneficiary.Id == beneficiaryId
                  )?.MobilePhone
                }
                error={
                  contract?.MobilePhone && contract?.MobilePhone?.length !== 10
                }
                helperText={
                  contract?.MobilePhone &&
                  contract?.Beneficiaries?.find(
                    (beneficiary) => beneficiary.Id == beneficiaryId
                  )?.MobilePhone?.length !== 10 &&
                  "Ingrese un número de teléfono valido"
                }
                inputProps={{
                  maxLength: 10,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Genero</FormLabel>
                <RadioGroup
                  aria-label="Gender"
                  name="Gender"
                  value={
                    contract &&
                    contract?.Beneficiaries?.find(
                      (beneficiary) => beneficiary.Id == beneficiaryId
                    )?.Gender
                      ? contract?.Beneficiaries?.find(
                          (beneficiary) => beneficiary.Id == beneficiaryId
                        )?.Gender
                      : ""
                  }
                  onChange={(event) => {
                    let beneficiary = {
                      ...contract?.Beneficiaries?.find(
                        (beneficiary) => beneficiary.Id == beneficiaryId
                      ),
                      [event.target.name]: event.target.value.toUpperCase(),
                    };
                    let beneficiaries =
                      contract?.Beneficiaries?.filter(
                        (beneficiary) => beneficiary.Id != beneficiaryId
                      ) ?? [];
                    setContract({
                      ...contract,
                      ...{ Beneficiaries: beneficiaries.concat(beneficiary) },
                    });
                  }}
                  style={{ flexDirection: "row" }}
                >
                  <FormControlLabel
                    value="F"
                    control={<Radio />}
                    label="Femenino"
                  />
                  <FormControlLabel
                    value="M"
                    control={<Radio />}
                    label="Masculino"
                  />
                  <FormControlLabel
                    value="I"
                    control={<Radio />}
                    label="Indeterminado"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Autocomplete
                name="Relationship"
                options={relations}
                margin="normal"
                getOptionLabel={(option) => `${option}`}
                style={{ width: "100%", marginTop: "8px" }}
                value={
                  contract?.Beneficiaries?.find(
                    (beneficiary) => beneficiary.Id == beneficiaryId
                  )?.Relationship ?? ""
                }
                onChange={(event, value) => {
                  handleChangeRelationshipBeneficiary(event, value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Parentesco"
                    name="Relationship"
                    variant={inputVariant}
                    onChange={(event, value) => {
                      handleChangeRelationshipBeneficiary(event, value);
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <DialogContentText
            style={{
              color: "#00309c",
              textTransform: "uppercase",
              fontWeight: "500",
              margin: "1rem 0",
            }}
          >
            Dirección
          </DialogContentText>

          <Grid container direction="row" alignItems="flex-start" spacing={1}>
            <Grid item xs={12} sm={3}>
              <TextField
                name="ZipCode"
                variant={inputVariant}
                label="Código postal"
                value={
                  contract?.Beneficiaries?.find(
                    (beneficiary) => beneficiary.Id == beneficiaryId
                  )?.Address &&
                  (contract?.Beneficiaries?.find(
                    (beneficiary) => beneficiary.Id == beneficiaryId
                  )?.Address[0]?.ZipCode ??
                    "")
                }
                onChange={(event) => {
                  handleChangeAddressBeneficiary(event);
                  getZipCodeDataBeneficiary(event);
                }}
                fullWidth
                inputProps={{
                  maxLength: 5,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={9}>
              <TextField
                name="Street"
                variant={inputVariant}
                label="Calle"
                onChange={(event) => {
                  handleChangeAddressBeneficiary(event);
                }}
                value={
                  contract?.Beneficiaries?.find(
                    (beneficiary) => beneficiary.Id == beneficiaryId
                  )?.Address &&
                  (contract?.Beneficiaries?.find(
                    (beneficiary) => beneficiary.Id == beneficiaryId
                  )?.Address[0]?.Street ??
                    "")
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                name="StreetNo"
                variant={inputVariant}
                label="N° Exterior"
                onChange={(event) => {
                  handleChangeAddressBeneficiary(event);
                }}
                value={
                  contract?.Beneficiaries?.find(
                    (beneficiary) => beneficiary.Id == beneficiaryId
                  )?.Address &&
                  (contract?.Beneficiaries?.find(
                    (beneficiary) => beneficiary.Id == beneficiaryId
                  )?.Address[0]?.StreetNo ??
                    "")
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                name="StreetNoInt"
                margin="small"
                variant={inputVariant}
                label="N° Interior"
                onChange={(event) => {
                  handleChangeAddressBeneficiary(event);
                }}
                value={
                  contract?.Beneficiaries?.find(
                    (beneficiary) => beneficiary.Id == beneficiaryId
                  )?.Address &&
                  (contract?.Beneficiaries?.find(
                    (beneficiary) => beneficiary.Id == beneficiaryId
                  )?.Address[0]?.StreetNoInt ??
                    "")
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <Autocomplete
                name="Suburb"
                options={suburbs}
                margin="normal"
                freeSolo
                getOptionLabel={(option) => `${option}`}
                style={{ width: "100%", marginTop: "8px" }}
                value={
                  contract?.Beneficiaries?.find(
                    (beneficiary) => beneficiary.Id == beneficiaryId
                  )?.Address &&
                  (contract?.Beneficiaries?.find(
                    (beneficiary) => beneficiary.Id == beneficiaryId
                  )?.Address[0]?.Suburb ??
                    "")
                }
                onChange={(event) => {
                  handleChangeAddressBeneficiary(event);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Colonia"
                    name="Suburb"
                    variant={inputVariant}
                    onChange={(event) => {
                      handleChangeAddressBeneficiary(event);
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                name="County"
                variant={inputVariant}
                label="Delegación/Alcaldia"
                value={
                  contract?.Beneficiaries?.find(
                    (beneficiary) => beneficiary.Id == beneficiaryId
                  )?.Address
                    ? contract?.Beneficiaries?.find(
                        (beneficiary) => beneficiary.Id == beneficiaryId
                      )?.Address[0]?.County
                    : ""
                }
                onChange={(event) => {
                  handleChangeAddressBeneficiary(event);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                name="City"
                variant={inputVariant}
                margin="normal"
                label="Ciudad"
                value={
                  contract?.Beneficiaries?.find(
                    (beneficiary) => beneficiary.Id == beneficiaryId
                  )?.Address
                    ? contract?.Beneficiaries?.find(
                        (beneficiary) => beneficiary.Id == beneficiaryId
                      )?.Address[0]?.City
                    : ""
                }
                onChange={(event) => {
                  handleChangeAddressBeneficiary(event);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Autocomplete
                name="StateCode"
                options={states}
                getOptionLabel={(option) => `${option.Name ?? ""}`}
                style={{ width: "100%", marginTop: "8px" }}
                value={
                  states.length > 0 &&
                  contract?.Beneficiaries?.find(
                    (beneficiary) => beneficiary.Id == beneficiaryId
                  )?.Address &&
                  states.find(
                    (state) =>
                      state.Code ===
                        contract?.Beneficiaries?.find(
                          (beneficiary) => beneficiary.Id == beneficiaryId
                        )?.Address[0]?.StateCode ?? ""
                  ) !== undefined
                    ? states.find(
                        (state) =>
                          state.Code ===
                            contract?.Beneficiaries?.find(
                              (beneficiary) => beneficiary.Id == beneficiaryId
                            )?.Address[0]?.StateCode ?? ""
                      )
                    : ""
                }
                onChange={props.handleStateChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Estado"
                    variant={inputVariant}
                  />
                )}
              />
              {/* <TextFi
          name="StateCode"
          variant={inputVariant
          label={intl.formatMessage({
            id: "INPUT.STATE",
          })}
          value={
            states.length > 0 &&
            states.find((state) => state.Code === contract?.StateCode ?? "") !==
              undefined
              ? states.find((state) => state.Code === contract?.StateCode ?? "")
                  .Name
              : ""
          }
        /> */}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
        <div className={classes.wrapper}>
          <Button
            onClick={() => {
              setOpenBeneficiaryDialog(false);
            }}
            color="primary"
            disabled={loading}          
          >
            Cancelar
          </Button>
          {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
          <div className={classes.wrapper}>
            <Button
              onClick={props.handleSaveData}
              variant="contained"
              color="primary"
              className={buttonClassname}
              disabled={loading}
            >
              Guardar
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openCardsDialog}
        aria-labelledby="form-dialog-cards"
      >
        <DialogTitle id="form-dialog-cards">Editar</DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{
              color: "#00309c",
              textTransform: "uppercase",
              fontWeight: "500",
            }}
          >
            Datos de forma de pago
          </DialogContentText>
          <Grid container direction="row" alignItems="flex-start" spacing={1}>
            {contract?.CardsRegistry?.filter((card) => card.Id == cardId)?.map(
              (payment) => (
                <>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      variant={inputVariant}
                      label="Nombre del titular"
                      className={contract && !contract.CardHolder ? active : ""}
                      name="CardHolder"
                      value={
                        contract?.CardsRegistry?.find(
                          (cardData) => cardData.Id === cardId
                        )?.CardHolder
                      }
                      onChange={(event) => {
                        handleChangeCard(event);
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      variant={inputVariant}
                      label="Número de la tarjeta"
                      name="CardNumber"
                      value={
                        contract?.CardsRegistry?.find(
                          (cardData) => cardData.Id === cardId
                        )?.CardNumber
                      }
                      onChange={(event) => {
                        handleChangeCard(event);
                      }}
                      type="number"
                      inputProps={{
                        maxLength: 16,
                      }}
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 16);
                      }}
                      error={
                        contract?.CardsRegistry?.find(
                          (cardData) => cardData.Id === cardId
                        )?.BankId === 6 &&
                        contract?.CardsRegistry?.find(
                          (cardData) => cardData.Id === cardId
                        )?.CardNumber?.length !== 15
                          ? true
                          : contract?.CardsRegistry?.find(
                              (cardData) => cardData.Id === cardId
                            )?.BankId !== 6 &&
                            contract?.CardsRegistry?.find(
                              (cardData) => cardData.Id === cardId
                            )?.CardNumber?.length !== 16
                          ? true
                          : false
                      }
                      helperText={
                        contract?.CardsRegistry?.find(
                          (cardData) => cardData.Id === cardId
                        )?.BankId === 6 &&
                        contract?.CardsRegistry?.find(
                          (cardData) => cardData.Id === cardId
                        )?.CardNumber?.length !== 15
                          ? "El número de la tarjeta debe ser 15 dígitos"
                          : contract?.CardsRegistry?.find(
                              (cardData) => cardData.Id === cardId
                            )?.BankId !== 6 &&
                            contract?.CardsRegistry?.find(
                              (cardData) => cardData.Id === cardId
                            )?.CardNumber?.length !== 16
                          ? "El número de la tarjeta debe ser 16 dígitos"
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      options={banks}
                      getOptionLabel={(option) => option.Name}
                      name="BankId"
                      value={banks.find(
                        (item) =>
                          item.Code ===
                          contract?.CardsRegistry?.find(
                            (cardData) => cardData.Id === cardId
                          )?.BankId
                      )}
                      onChange={(event, newValue) => {
                        handleChangeBank(event, newValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Banco" />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Autocomplete
                      options={optionsType}
                      getOptionLabel={(option) => option.Name}
                      name="CardType"
                      value={optionsType.find(
                        (item) =>
                          item.Code ===
                          contract?.CardsRegistry?.find(
                            (cardData) => cardData.Id === cardId
                          )?.CardType
                      )}
                      onChange={(event, newValue) => {
                        handleChangeCard(event, newValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Tipo de trajeta" />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <MuiPickersUtilsProvider
                      utils={DateFnsUtils}
                      locale={esLocale}
                    >
                      <KeyboardDatePicker
                        required
                        fullWidth
                        autoOk
                        variant="inline"
                        inputVariant={inputVariant}
                        label="Fecha de expiración"
                        format="MM/yy"
                        InputAdornmentProps={{ position: "end" }}
                        onChange={(date) => {
                          if (isValid(date)) {
                            let card = {
                              ...contract?.CardsRegistry?.find(
                                (card) => card.Id == cardId
                              ),
                              Expiration: date,
                            };
                            let cards =
                              contract?.CardsRegistry?.filter(
                                (card) => card.Id != cardId
                              ) ?? [];
                            setContract({
                              ...contract,
                              ...{ CardsRegistry: cards.concat(card) },
                            });
                          }
                        }}
                        className={
                          contract && !contract.Expiration ? active : ""
                        }
                        name="Expiration"
                        value={
                          contract?.CardsRegistry?.find(
                            (cardData) => cardData.Id === cardId
                          )?.Expiration
                        }
                        minDate={new Date()}
                        maxDate={add(new Date(), { years: 10 })}
                        views={["year", "month"]}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  {contract?.CardsRegistry?.find(
                            (cardData) => cardData.Id === cardId
                          )?.PaymentType === "1" && (
                    <Grid item xs={6} sm={4}>
                    <TextField
                      required
                      fullWidth
                      variant={inputVariant}
                      label="CVV"
                      type="password"
                      className={contract && !contract.CVVCard ? active : ""}
                      name="CVVCard"
                      value={
                        contract?.CardsRegistry?.find(
                          (cardData) => cardData.Id === cardId
                        )?.CVVCard
                      }
                      onChange={(event) => {
                        handleChangeCard(event);
                      }}
                      inputProps={{
                        maxLength: 4,
                        minLength: 3,
                      }}
                    />
                  </Grid>
                  )}
                  
                </>
              )
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
        <div className={classes.wrapper}>
          <Button
            onClick={() => {
              setOpenCardsDialog(false);
            }}
            color="primary"
            disabled={loading}
            >
              Cancelar
            </Button>
            {loading && (
                <CircularProgress size={24} className={classes.buttonProgress} />
              )}
            </div>
          <div className={classes.wrapper}>
            <Button
              onClick={props.handleSaveData}
              variant="contained"
              color="primary"
              className={buttonClassname}
              disabled={loading ||
                contract?.CardsRegistry?.find(
                  (cardData) => cardData.Id === cardId
                )?.CVVCard?.length < 3 ||
                contract?.CardsRegistry?.find(
                  (cardData) => cardData.Id === cardId
                )?.CVVCard?.length > 4
              }
            >
              Guardar
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>




          {/* <Button
            onClick={props.handleSaveData}
            color="primary"
            disabled={
              contract?.CardsRegistry?.find(
                (cardData) => cardData.Id === cardId
              )?.CVVCard?.length < 3 ||
              contract?.CardsRegistry?.find(
                (cardData) => cardData.Id === cardId
              )?.CVVCard?.length > 4
            }
          >
            Guardar
          </Button> */}
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDateDialog}
        aria-labelledby="form-dialog-cards"
      >
        <DialogTitle id="form-dialog-cards">Editar</DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{
              color: "#00309c",
              textTransform: "uppercase",
              fontWeight: "500",
            }}
          >
            Fecha de primer vencimiento
          </DialogContentText>

          <Grid container direction="row" alignItems="flex-start" spacing={1}>
            <Grid item xs={12} sm={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <KeyboardDatePicker
                  required
                  fullWidth
                  autoOk
                  variant="inline"
                  inputVariant={inputVariant}
                  label="Fecha"
                  format="dd/MM/yyyy"
                  InputAdornmentProps={{ position: "end" }}
                  onChange={(date) => {
                    if (isValid( date ) && date <= add( new Date( contract.ContractDate ), { days: 45  } )) {
                      setContract({
                        ...contract,
                        ...{ FirstPaymentAt: date },
                      });
                    }
                  }}
                  className={contract && !contract.FirstPaymentAt ? active : ""}
                  name="FirstPaymentAt"
                  value={new Date(contract?.FirstPaymentAt)}
                  minDate={new Date(contract.ContractDate)}
                  maxDate={add(new Date(contract.ContractDate), { days: 45 })}
                  views={["year", "month", "date"]}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
        <div className={classes.wrapper}>
          <Button
            onClick={() => {
              setOpenDateDialog(false);
            }}
            color="primary"
            disabled={loading}
            
            >
              Cancelar
            </Button>
            {loading && (
                <CircularProgress size={24} className={classes.buttonProgress} />
              )}
            </div>
          <div className={classes.wrapper}>
            <Button
              onClick={props.handleSaveData}
              variant="contained"
              color="primary"
              className={buttonClassname}
              disabled={loading}
            >
              Guardar
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ClientEdit;
