import React, { useRef, useState, useEffect } from "react";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import { createTheme, makeStyles, ThemeProvider } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";

import queryString from "query-string";
import Contract from './Contract';


const useStyles = makeStyles( (theme) => ( {
   "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
} ) );


export default function RemoteSignatureAuthorization( props ) {
  const [signatureAuthorized, setSignatureAuthorized] = useState( false );
  const [authorizationCode, setAuthorizationCode] = useState( "" );
  const [loadingForm, setLoadingForm] = useState( true );
  const [errorAuth, setErrorAuth] = useState( {} );

  const classes = useStyles();

  let dataQuery = queryString.parse( window.location.search );

  const handleClickValid = () => {
      let urlFetch = `${process.env.REACT_APP_PUBLIC_URL}/contract/remote-signature?id=${dataQuery.Id}&authCode=${authorizationCode}`;

      setLoadingForm( true );
  
      fetch( urlFetch )
          .then( (response) => response.json() )
              .then( (data) => {
                  //console.log( "DDDDDDD DATA DDDDDDDD", data );
                  if (data.body.RemoteSignatureAutorized) {
                    setSignatureAuthorized( true );

                  } else {
                    console.log( data?.body.message[ 0 ] );
                    setErrorAuth( data.body );

                  }
               } )
                  .catch( (error) => { 
                    setErrorAuth( error )
                  } )
                  .finally( () => setLoadingForm( false ) );

    };

    const handleChangeRemoteSignatureAutorized = async ( event) => {
      try {
        if (event.target.value ) {
          setAuthorizationCode( event.target.value );
        }
      } catch {
        setAuthorizationCode( null );

      }
    };

    useEffect( () => {
      setLoadingForm( false );
    }, [] );

    return (
      <Container>
        <Grid item xs={12} sm={12}>
          <Typography
            variant="h5"
            align="center"
            color="textSecondary"
            component="p"
            style={ {
                backgroundImage: "linear-gradient(to right, rgb(25 3 80), rgb(49 8 148))",
                color: "#fff",
                margin: "4rem 0 2rem",
                padding: "0.5rem",
                boxShadow: "0.5rem 0.5rem .25rem #a0a0a0b8",
                borderRadius: "0.5rem",
              } }
          >
            { !signatureAuthorized ? "Código de Autorización para " : "" }Firma a Distancia
            {loadingForm && (
                          <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                          />
                        )}
            { errorAuth?.error && errorAuth.error > 400 ? 
                <ul> { errorAuth.message.map( (errorName) => <li> {errorName} </li>) } </ul> :<></> }
          </Typography>
        </Grid>

        { !signatureAuthorized &&
        <Container align="center">
            <Grid item md={6}>
                <TextField
                  type="text"
                  label="ingresa Código de Autorización"
                  name="RemoteSignatureAutorized"
                  onChange={handleChangeRemoteSignatureAutorized}
                  fullWidth 
                  />
            </Grid>
            <br></br>
            <Grid item md={12}>
              <Button
                align="center"
                variant="contained"
                color="primary"
                onClick={handleClickValid}
              >
                Enviar
              </Button>
            </Grid>
          </Container>
        }

        { signatureAuthorized && <Contract /> }
        
      </Container>
    );

}